import React from 'react';


const Privacy = (props) => {

	
	return (
    <div className="row">
      
      <div className="col-md-10 mx-auto">
        
        <div className="column content">
          
          <section className="">
            <h1>Privacy Policy</h1>

            <h2>1. Introduction</h2> 
			<p>Welcome to flyent</p>
            <p>
              Satoji Kaida, MB. (“us”, “we”, or “our”) operates https://flyent.online
              and flyent mobile application (hereinafter referred to as
              “Service”).
            </p>
            <p>
              Our Privacy Policy governs your visit to https://flyent.online and flyent mobile application, and explains
              how we collect, safeguard and disclose information thatt results from your use of our Service.
            </p>
            <p>
			  We use your data to provide and improve Service. 
			  By using Service, you agree to the collection and use of information in accordance with this policy. 
			  Unless otherwise defined in this Privacy Policy, 
			  the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
            </p>
            <p>
              Our Terms and Conditions (“Terms”) govern all use of our Service
              and together with the Privacy Policy constitutes your agreement
              with us (“agreement”).
            </p>
            <h2>2. Definitions</h2>
            <p>
              SERVICE means the https://flyent.online website 
			  and flyent mobile application operated by Satoji Kaida, MB.
            </p>
            <p>
              PERSONAL DATA means data about a living individual who can be
              identified from those data (or from those and other information
              either in our possession or likely to come into our possession).
            </p>
            <p>
              USAGE DATA is data collected automatically either generated by the
              use of Service or from Service infrastructure itself (for example,
              the duration of a page visit).
            </p>
            <p>
              COOKIES are small files stored on your device (computer or mobile
              device).
            </p>
            <p>
              DATA CONTROLLER means a natural or legal person who (either alone
              or jointly or in common with other persons) determines the
              purposes for which and the manner in which any personal data are,
              or are to be, processed. For the purpose of this Privacy Policy,
              we are a Data Controller of your data.
            </p>
            <p>
              DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal
              person who processes the data on behalf of the Data Controller. We
              may use the services of various Service Providers in order to
              process your data more effectively.
            </p>
            <p>
              DATA SUBJECT is any living individual who is the subject of
              Personal Data.
            </p>
            <p>
              THE USER is the individual using our Service. The User corresponds
              to the Data Subject, who is the subject of Personal Data.
            </p>

            <h2>3. Information Collection and Use</h2>
            <p>
              We collect several different types of information for various
              purposes to provide and improve our Service to you.
            </p>

            <h2>4. Types of Data Collected</h2>
            <p>
              
              <strong>Personal Data</strong> <br />
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you (“Personal Data”). Personally identifiable
              information may include, but is not limited to
            </p>
            <ol type="a">
              
              <li>Email address</li> <li>First name and last name</li>
              <li>Cookies and Usage Data</li>
            </ol>
            <p>
              We may use your Personal Data to contact you with newsletters,
              marketing or promotional materials and other information that may
              be of interest to you. You may opt out of receiving any, or all,
              of these communications from us by following the unsubscribe link
              or by emailing at help@flyent.com.
            </p>
            <p>
              
              <strong>Usage Data</strong> <br />
              We may also collect information that your browser sends whenever
              you visit our Service or when you access Service by or through a
              mobile device (“Usage Data”).
            </p>
            <p>
              This Usage Data may include information such as your computer's
              Internet Protocol address (e.g. IP address), browser type, browser
              version, the pages of our Service that you visit, the time and
              date of your visit, the time spent on those pages, unique device
              identifiers and other diagnostic data.
            </p>
            <p>
              When you access Service with a mobile device, this Usage Data may
              include information such as the type of mobile device you use,
              your mobile device unique ID, the IP address of your mobile
              device, your mobile operating system, the type of mobile Internet
              browser you use, unique device identifiers and other diagnostic
              data.
            </p>
            <p>
              
              <strong>Tracking Cookies Data</strong> <br />
              We use cookies and similar tracking technologies to track the
              activity on our Service and we hold certain information.
            </p>
            <p>
              Cookies are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Other tracking technologies
              are also used such as beacons, tags and scripts to collect and
              track information and to improve and analyze our Service.
            </p>
            <p>
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </p>
            <p>Examples of Cookies we use:</p>
            <ol type="a">
              
              <li>
                Session Cookies: We use Session Cookies to operate our Service.
              </li>
              <li>
                Preference Cookies: We use Preference Cookies to remember your
                preferences and various settings.
              </li>
              <li>
                Security Cookies: We use Security Cookies for security purposes.
              </li>
            </ol>
            <h2>5. Use of Data</h2>
            <p>Satoji Kaida, MB. uses the collected data for various purposes:</p>
            <ol type="a">
              
              <li>to provide and maintain our Service;</li>
              <li>to notify you about changes to our Service;</li>
              <li>
                to allow you to participate in interactive features of our
                Service when you choose to do so;
              </li>
              <li>to provide customer support;</li>
              <li>
                to gather analysis or valuable information so that we can
                improve our Service;
              </li>
              <li>to monitor the usage of our Service;</li>
              <li>to detect, prevent and address technical issues;</li>
              <li>to fulfill any other purpose for which you provide it;</li>
              <li>
                to carry out our obligations and enforce our rights arising from
                any contracts entered into between you and us, including for
                billing and collection;
              </li>
              <li>
                to provide you with notices about your account and/or
                subscription, including expiration and renewal notices,
                email-instructions, etc.;
              </li>
              <li>
                to provide you with news, special offers and general information
                about other goods, services and events which we offer that are
                similar to those that you have already purchased or enquired
                about unless you have opted not to receive such information;
              </li>
              <li>
                in any other way we may describe when you provide the
                information;
              </li>
              <li>for any other purpose with your consent.</li>
            </ol>

            <h2>6. Retention of Data</h2>
            <p>
              We will retain your Personal Data only for as long as is necessary
              for the purposes set out in this Privacy Policy. We will retain
              and use your Personal Data to the extent necessary to comply with
              our legal obligations (for example, if we are required to retain
              your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies.
            </p>
            <p>
              We will also retain Usage Data for internal analysis purposes.
              Usage Data is generally retained for a shorter period, except when
              this data is used to strengthen the security or to improve the
              functionality of our Service, or we are legally obligated to
              retain this data for longer time periods.
            </p>

            <h2>7. Transfer of Data</h2>
            <p>
              Your information, including Personal Data, may be transferred to –
              and maintained on – computers located outside of your 
			  country or other governmental jurisdiction where the
              data protection laws may differ from those of your jurisdiction.
            </p>

            <p>
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
            </p>
            <p>
              Satoji Kaida, MB, will take all the steps reasonably necessary to
              ensure that your data is treated securely and in accordance with
              this Privacy Policy and no transfer of your Personal Data will
              take place to an organisation or a country unless there are
              adequate controls in place including the security of your data and
              other personal information.
            </p>
            <h2>8. Disclosure of Data</h2>
            <p>
              We may disclose personal information that we collect, or you
              provide:
            </p>
            <ol type="a">
              
              <li>
                
                <strong>Disclosure for Law Enforcement.</strong> <br />
                Under certain circumstances, we may be required to disclose your
                Personal Data if required to do so by law or in response to
                valid requests by public authorities.
              </li>
              <li>
                
                <strong>Business Transaction.</strong> <br />
                If we or our subsidiaries are involved in a merger, acquisition
                or asset sale, your Personal Data may be transferred.
              </li>

            </ol>

            <h2>9. Security of Data</h2>
            <p>
              The security of your data is important to us but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </p>
            <h2>
              10. Your Data Protection Rights Under General Data Protection
              Regulation (GDPR)
            </h2>
            <p>
              If you are a resident of the European Union (EU) and European
              Economic Area (EEA), you have certain data protection rights,
              covered by GDPR. – See more at
              https://eur-lex.europa.eu/eli/reg/2016/679/oj
            </p>
            <p>
              We aim to take reasonable steps to allow you to correct, amend,
              delete, or limit the use of your Personal Data.
            </p>
            <p>
              If you wish to be informed what Personal Data we hold about you
              and if you want it to be removed from our systems, please email us
              at help@flyent.com.
            </p>
            <p>
              In certain circumstances, you have the following data protection
              rights:
            </p>
            <ol type="a">
              
              <li>
                the right to access, update or to delete the information we have
                on you;
              </li>
              <li>
                the right of rectification. You have the right to have your
                information rectified if that information is inaccurate or
                incomplete;
              </li>
              <li>
                the right to object. You have the right to object to our
                processing of your Personal Data;
              </li>
              <li>
                the right of restriction. You have the right to request that we
                restrict the processing of your personal information;
              </li>
              <li>
                the right to data portability. You have the right to be provided
                with a copy of your Personal Data in a structured,
                machine-readable and commonly used format;
              </li>
              <li>
                the right to withdraw consent. You also have the right to
                withdraw your consent at any time where we rely on your consent
                to process your personal information;
              </li>
            </ol>
            <p>
              Please note that we may ask you to verify your identity before
              responding to such requests. Please note, we may not able to
              provide Service without some necessary data.
            </p>
            <p>
              You have the right to complain to a Data Protection Authority
              about our collection and use of your Personal Data. For more
              information, please contact your local data protection authority
              in the European Economic Area (EEA).
            </p>


            <h2>11. Service Providers</h2>
            <p>
              We may employ third party companies and individuals to facilitate
              our Service (“Service Providers”), provide Service on our behalf,
              perform Service-related services or assist us in analysing how our
              Service is used.
            </p>
            <p>
              These third parties have access to your Personal Data only to
              perform these tasks on our behalf and are obligated not to
              disclose or use it for any other purpose.
            </p>

            <h2>12. Analytics</h2>
            <p>
              We may use third party services (including Auth0, Amplitude,
              Segment, Google and their respective
              affiliates) that collect, monitor and analyze this Log Data to
              provide analytics and other data to help us increase our Service’s
              functionality and to help us advertise our products and services.
            </p>

            <h2>13. Payments</h2>
            <p>
              We may provide paid products and/or services within Service. In
              that case, we use third-party services for payment processing
              (e.g. payment processors).
            </p>
            <p>
              We will not store or collect your payment card details. That
              information is provided directly to our third-party payment
              processors whose use of your personal information is governed by
              their Privacy Policy. These payment processors adhere to the
              standards set by PCI-DSS as managed by the PCI Security Standards
              Council, which is a joint effort of brands like Visa, Mastercard,
              American Express and Discover. PCI-DSS requirements help ensure
              the secure handling of payment information.
            </p>
            <p>The payment processors we work with are:</p>
            <p>
              
              <strong>Stripe</strong> <br />
              Their Privacy Policy can be viewed at:
              https://stripe.com/us/privacy
            </p>
            <h2>14. Links to Other Sites</h2>
            <p>
              Our Service may contain links to other sites that are not operated
              by us. If you click a third party link, you will be directed to
              that third party's site. We strongly advise you to review the
              Privacy Policy of every site you visit.
            </p>
            <p>
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>

            <h2>15. Changes to This Privacy Policy</h2>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
            </p>
            <p>
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update
              “effective date” at the top of this Privacy Policy.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
            <h2>16. Contact us</h2>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us by email: <span>team</span>@<span>flyent.online</span>.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
