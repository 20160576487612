import React from 'react';
import { Context } from '../../common/Context';
import RedDot from './RedDot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from "@auth0/auth0-react";


const Avatar = () => {

	const {state, dispatch} = React.useContext(Context);

	const { user } = useAuth0();
	let src = getImageUrl();
	
	if (src) {
		return (
			<React.Fragment>
				<img src={src} className="avatar" onClick={() => toggleMenu()} />
			</React.Fragment>
		)
	} else {//anonymous or no avatar
		return (
			<FontAwesomeIcon icon={faUser} className="avatar" onClick={forceLogin}/>
		)			
	}

	function getImageUrl(){
		if (user) {
			if (user.sub.startsWith('facebook')) {
				let userId = user.sub.slice('facebook|'.length);
				return `https://graph.facebook.com/v4.0/${userId}/picture?type=normal`;
				//"https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=1025891714120517&height=50&width=50&ext=1605532601&hash=AeRW9lu2Zlq980hRyZU"
				//https://developers.facebook.com/docs/graph-api/reference/user/picture
				//userId === ASID
			} else {
				return user.picture;
			}			
		}
		else return null;
	}

	async function toggleMenu() {
		var userMenuVisible = state.userMenuVisible;
		setMenuVisibility(!userMenuVisible);
	}

	function forceLogin(){
		dispatch({
			type: 'FORCE_LOGIN',
			payload: {forceLogin: true}
		});
	}

	function setMenuVisibility(userMenuVisible){
		dispatch({
			type: 'MENU_VISIBLE',
			payload: { userMenuVisible }
		})
	}
}


export default Avatar;