import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
// import './MyPhrases.scss';
import { Context } from '../../../common/Context';
import Spinner from '../../../common/ui/Spinner';
import useQueryAuthenticated from '../../../common/use_authenticated_query';
import useSetVisited from '../dot_visited';


const MyPhrases = (props) => {
	const { state: { langData } } = React.useContext(Context);
	const { isLoading, data: phrases } = useQueryAuthenticated('phrases', loadPhrases);
	const { t } = useTranslation();

	useSetVisited('phrases');

	if (isLoading) {
		return (<Spinner/>);
	}

	const emptyState = (phrases.length === 0);
	
	return (
		<div style={{backgroundColor: '#FAFAFA'}}>
			<h1>
				<i className="icon-bookmark-empty icon"></i>
				{t('my-phrases-title')}
			</h1>

			{emptyState && 
				<div className="userPageText">
					{ReactHtmlParser(t('my-phrases-empty'))}
				</div>
			}

			{phrases.map((phrase, index) => <Phrase phrase={phrase} key={index} />)}
		</div>
	);

	async function loadPhrases() {
		let response = await axios.get(`/Students/cards?lang=${langData.knownLang}`);
		return response.data;
	}
}

const Phrase = ({phrase}) => {
	const queryClient = useQueryClient();
	const mutation = useMutation(removePhrase, {
		onSettled: () => queryClient.invalidateQueries(['phrases', true])
	});
	const { state: { langData } } = React.useContext(Context);

	return (
		<div className="favPhrasePair">
			<div className="favPhrase favPhraseOriginal">{phrase.original}</div>
			<div className="favPhrase favPhraseTranslated">{phrase.translated}</div>
			<i class="icon-trash-empty" onClick={() => mutation.mutate()}></i>
		</div>
	)

	async function removePhrase() {
		await axios.post(`/Students/card/${phrase.phraseId}/${langData.knownLang}`, {isSaved: false});
	}
}

export default MyPhrases;

// function useQueryAuthenticated(queryKey, queryFn, config){
// 	const { state: {serverAuthenticated} } = React.useContext(Context);
// 	if (!Array.isArray(queryKey)) {
// 		queryKey = [queryKey];
// 	}
// 	queryKey.push(serverAuthenticated);
// 	let { isLoading, isSuccess, data } = useQuery(queryKey, serverAuthenticated && queryFn, config);
// 	if (!serverAuthenticated) {
// 		isLoading = true;
// 		isSuccess = false;
// 	}
// 	return { isLoading, isSuccess, data }
// }
