import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { PanelGroup } from 'react-bootstrap';

import Module from './Module';

import './list.scss';
import queryString from 'query-string';
import format from 'string-format';
import Spinner from '../../../common/ui/Spinner';
import { Context } from '../../../common/Context';
import Filter from './Filter';
import { useAuth0 } from '@auth0/auth0-react';
import { useLink } from 'valuelink';
// import FirstVisit from './FirstVisit';
import PleaseReferPopup from '../player/PleaseReferPopup';
import WeHaveAnAppPopup from './WeHaveAnAppPopup';
import useLocalStorage from '@rehooks/local-storage';
format.extend(String.prototype, {});


const List = (props) => {

	const { isAuthenticated, isLoading:authLoading } = useAuth0();
	const [groupedItems, setGroupedItems] = useState();
	const [selectedGroup, setSelectedGroup] = useState();
	const { t } = useTranslation();
	const {state: {langData, serverAuthenticated, selectedLevel}, dispatch} = React.useContext(Context);
	const filter = useLink(selectedLevel?? null);
	const { isLoading, data:items } = useQuery(['items', isAuthenticated, serverAuthenticated, authLoading, langData],  loadItems, { refetchOnWindowFocus: false });
	const [ lastPlayed ] = useLocalStorage('lastPlayed');	//last played item
	const suggestionsQuery = useQuery(['suggestions', lastPlayed, langData], loadSuggestions, { refetchOnWindowFocus: false });

	//TODO: if items.length === 0 return "nothing's here"

	React.useEffect(() => {
		if (!items || !langData || isLoading || authLoading) return;

		let filteredItems = filterItems(items);

		//add module name
		filteredItems.forEach(item => {
			item.displayedModuleName = getModuleName(item);
		});

		let groupedItems =  groupItems(filteredItems);
		setGroupedItems(groupedItems);

		let selectedGroup = decodeURI(window.location.hash.substring(1)) || Object.keys(groupedItems)[0];
		setSelectedGroup(selectedGroup);

		//reset the current item
		setCurrentItem(null);

	}, [items, filter.value, suggestionsQuery.data]);


	if (!langData) {
		return (<Spinner/>);
	}
	if (isLoading || authLoading) {
		return (<Spinner/>);
	}
	
	async function loadItems(){

		if (!langData || authLoading) {
			return null;
		}

		// //if we have the last item but not authenticated, wait till we are
		// if (lastPlayed && !serverAuthenticated) {
			
		// }

		if ((langData.learnedLang || !langData.translatedOnly) && langData.knownLang) {
			let url = "/Students/List/items?" + queryString.stringify(langData) + `&authenticated=${serverAuthenticated}`;
			let response = await axios.get(url, langData);
			let items = response.data;

			if (items.length === 0) {
				console.error(`Empty result set: ${JSON.stringify(langData)}`);
			}

			return items;
		}

		//something's wrong; add a log record and return an empty object to avoid errors
		console.error(`Incomplete lang data: ${JSON.stringify(langData)}`);
		return [];

	}

	function filterItems(items) {
		if (filter.value !== null) {
			return items.filter(item => item.contentItem.level == filter.value);
		}
		return items;
	}

	function groupItems(items) {

		items.orderBy(item => item.lastPlayed ? 1 : 0);

		//group by modulename
		let reducer = (grouped, item) => {
			let key = item.displayedModuleName;
			grouped[key] = grouped[key] || [];
			grouped[key].push(item);
			grouped[key] = grouped[key].orderBy("contentItem.rate desc");
			return grouped;
		}

		let initial = {};

		//suggestions
		const SUGGESTED = t('suggested');
		if (suggestionsQuery.data?.length) {
			let suggestedItems = suggestionsQuery.data
				.map(suggestedItem => items.find(item => item.contentItem.name === suggestedItem.itemName))
				.filter(item => item != null && item.lastPlayed === null)
				.orderBy("popularity desc");

			if (suggestedItems.length > 0) {
				initial[SUGGESTED] = suggestedItems;
				initial[SUGGESTED].type = 'suggested';			
			}
		}

		//new items
		const NEW = t('new');
		if (items.some(item => item.isNew)) {
			let played = items
				.filter(item => item.isNew)
				.orderBy("translationWhen desc");
			initial[NEW] = played;
			initial[NEW].type = 'new';
		}



		//if there's at least one played item, create a group for them first, so that they go first in the list
		const LAST_PLAYED = t('Last played');
		if (items.some(item => item.lastPlayed)) {
			let played = items
				.filter(item => item.lastPlayed)
				.orderBy("lastPlayed desc");
			initial[LAST_PLAYED] = played;
			initial[LAST_PLAYED].type = 'last_played';
		}

		let grouped = items.reduce(reducer, initial);
		return grouped;		
	}

	if (!groupedItems) {
		return (<Spinner/>);
	}
	
	return (
		<>
			<Filter value={filter}/>

			<PanelGroup
				accordion
				id="modules"
				activeKey={selectedGroup}
				onSelect={handleSelect}
				className="itemList"
			>

			{
				Object.entries(groupedItems)
					.map(([moduleName, items]) => ( 
						<Module moduleName = {moduleName} items = {items} isExpanded={selectedGroup === moduleName} key={moduleName}/>
					))
			} 
			</PanelGroup>

			
			{/* popup encouraging users to refer -- opens up on leaving the Player */}
			<PleaseReferPopup/>

			{/* popup informing about the app */}
			<WeHaveAnAppPopup/>
		</>
	)

	async function loadSuggestions(){
		if (!lastPlayed || !langData || !items)
			return null;

		const url = `/Students/List/suggestions/${lastPlayed}/${langData.knownLang}`;
		const response = await axios.get(url);
		return response.data;
	}

	function handleSelect(selectedGroup) {
		setSelectedGroup(selectedGroup);
		props.history.push('#' + selectedGroup);
	}


	function getModuleName(item) {
		return item.translation.moduleName || item.contentItem.moduleName || item.contentItem.category || item.translation.level || item.contentItem.level || 'default';
	}

	function getTitle(item) {
		return item.translation.title || item.contentItem.title || item.contentItem.name;
	}

	function setCurrentItem(item) {
		dispatch({
			type: 'SET_ITEM',
			payload: {
				currentItem: item
			}
		});
	}
}


export default withRouter(List);

//sorting utility
Array.prototype.orderBy = function(fields){
	const argIsArray = fields.length && fields[0] && typeof fields === "object";
	if (!argIsArray) {
		fields = [fields];
	}

	fields.forEach((field, idx) => {
		if (typeof field === "string") {
			const descending = field.endsWith(' desc');
			if(descending) field = field.substring(0, field.length - ' desc'.length);
			fields[idx] = x => {
				let result = x;
				field.split('.').forEach(part => {
					result = result[part];
				})
				return result.toString()
			};//"fields[idx]" is a function for comparison
			fields[idx].descending = descending;
		}
	});

	const comparer = (a, b) => {
		//compare fields one by one
		for (let fieldIndex = 0; fieldIndex < fields.length; fieldIndex++) {
			const field = fields[fieldIndex];
			const coeff = field.descending? 1 : -1;
			if (field(a) < field(b)) {
				return coeff;
			}
			if (field(a) > field(b)) {
				return -coeff;
			}			
		}
		
		return 0;	//all compared fields are equal
	}

	this.sort(comparer);

	return this;
}