import React from 'react';
import { OnUpdate } from 'rrc';

const Analytics = () => {
	//setup middleware

	/* eslint-disable */
	const [analyticsLoaded, setLoaded] = React.useState(!!window.analytics?.addSourceMiddleware);
	const [setupFinished, setSetupFinished] = React.useState(false);

	React.useEffect(() => {
		// console.log(`useEffect: analyticsLoaded: ${analyticsLoaded}`);
	
		if (analyticsLoaded) {
			const abMiddleware = function({ payload, integration, next }) {
				let props = payload.obj.properties || payload.obj.traits;
				if (!props) {
					props = payload.obj.properties = {};
				}
				next(payload);
			};
			window.analytics.addSourceMiddleware(abMiddleware);	
			// console.log("addSourceMiddleware");
			setSetupFinished(true);	
		} else {
			//if it's not loaded yet, add an onload handler so that we are notified when it loads
			window.analytics?.ready(() => { setLoaded(true); });
		}

	}, [analyticsLoaded]);

	if (!setupFinished) {
		return null;
	}

	return (
		<OnUpdate
			immediate
			call={(location) => {
				console.log(`Navigated to ${location.pathname + location.search}`);
				let metadata = getPageMetadata(location.pathname);
				window.analytics && window.analytics.page(metadata.category, metadata.name);
				// window.analytics.track(`Visited ${metadata.category}`,{
				// 	name: metadata.name
				// });
		}} />
	)
}

function getPageMetadata(path) {
    path = trimStart(path, '/Students');
    path = trimStart(path, '/');
    if (path === "") {
        return {
            category: "Home",
            name: ""
        };
    }

    let parts = path.split('/');
    return {
        category: parts[0],
        name: parts[1] || ""
    };
}

function trimStart(src, prefix) {
    if (src.startsWith(prefix)) {
        return src.substring(prefix.length);
    } else {
        return src;
    }
}

export default Analytics;
