import React, { Component } from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';
import i18next from 'i18next';
import en from './flags/en.png';
import ru from './flags/ru.png';

class Localizer extends Component {
    render() {
		if (!i18next.isInitialized) {
			return null;
		}

		var lang = i18next.language;
        return (
            <React.Fragment>
				<Dropdown id="choose-language" pullRight>
					{/* <span bsRole="toggle" >{lang}</span> */}
					<FlagSelector lang={lang} bsRole="toggle"/>
					<FlagMenu bsRole="menu" onSelect={lang => this.selectLang(lang)}/>

				</Dropdown>
            </React.Fragment>
        )
	}

	async selectLang(lang){
        await i18next.changeLanguage(lang);
	}
}

class FlagMenu extends Component {
	render() {
		return (
			<ul className="dropdown-menu">
				<Flag lang="ua" onSelect={lang => this.props.onSelect(lang)}/>
				<Flag lang="ru" onSelect={lang => this.props.onSelect(lang)}/>
				<Flag lang="en" onSelect={lang => this.props.onSelect(lang)}/>
			</ul>
		)
	}
}


class Flag extends Component {
	render() {
		let lang = this.props.lang;
		let src = lang === 'ru'? ru : en;
		// var req = require.context("./flags/", false, /.*\.png$/);
		// let keys = req.keys();
		return (<MenuItem eventKey="{lang}" onSelect={lang => this.onSelect()}>{lang}</MenuItem>);
	}

	onSelect() {
		if (this.props.onSelect) {
			this.props.onSelect(this.props.lang);
		}
	}
}

//menu toggler
class FlagSelector extends Component {
	render() {
		let lang = this.props.lang;
		// return (<span>{lang}</span>);
		let src = lang === 'ru'? ru : en;
		return (<span className="localizerSelector" onClick={e => this.handleClick(e)}>{lang}</span>);
		// return (<img src={src} onClick={e => this.handleClick(e)}/>);
	}


	handleClick(e) {
		e.preventDefault();

		this.props.onClick(e);
	  }
}

export default Localizer;
