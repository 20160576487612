import React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
//import { Context } from '../../../common/Context';
import Spinner from '../../../common/ui/Spinner';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Input } from 'linked-controls';
import visaLogo from './visa_logo.svg';
import mcLogo from './mc_logo.svg';

const logos = {
	visa: visaLogo,
	mastercard: mcLogo
};



const Methods = ({methodLink}) => {
	const { isLoading, data:methods, isError } = useQuery('methods',  getUsedMethods, { refetchOnWindowFocus: false });
	
	const isProduction = (process.env.NODE_ENV === 'production');

	//while waiting, show spinner
	if (isLoading) {
		return (<Spinner/>);
	}

	return (
		<>
			{(methods || []).map(method => <Method card={method.card} id={method.id} key={method.id} methodLink={methodLink} />)}
			<NewMethod methodLink={methodLink}/>
		</>
	)

	async function getUsedMethods(){
		let methods = (await axios.get(`/UserManagement/Payments/payment_methods?isProduction=${isProduction}`)).data;

		if (methods.length > 0) {
			methodLink.set(methods[0].id);    //the methods are ordered by Created descending, so last chosen method should be chosen
		}
		return methods;
	}
}										

const Method = ({card, id, methodLink}) => {
	return(
	<div className="pay-method" onClick={() => {methodLink.set(id)}}>
		<div className="pay-method-select">
			<Input type="radio" value={id} $value={methodLink} />
		</div>

		<div className="pay-card-logo">
			<img src={logos[card.brand]}/>
		</div>

		<div className="pay-card-details">
			<div className="pay-card-details-top">
				{card.brand} ····{card.last4}
			</div>
			<div className="pay-card-details-bottom">
				Expires on {card.exp_month}/{card.exp_year}
			</div>
		</div>
	</div>)
}

const NewMethod = ({methodLink}) =>{
	const { t } = useTranslation();

	return (
		<div className="pay-method" onClick={() => {methodLink.set("new")}}>
			<div className="pay-method-select">
				<Input type="radio" value="new" $value={methodLink} />
			</div>

			<div style={{marginLeft: '1em'}}>
				{t('pay-newcard')}
			</div>
		</div>	
	)
} 


export default Methods;
