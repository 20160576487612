import React from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from '../../../common/ui/Spinner';
import { Table } from 'react-bootstrap';
import SmileAndBuy from './SmileAndBuy';
import payments from './payments.svg';


const MyPayments = (props) => {
	const [data, setData] = React.useState(null);
	const { isAuthenticated } = useAuth0();

	React.useEffect(() => { loadHistory(); }, [isAuthenticated, data]);

	async function loadHistory() {
		if (isAuthenticated && !data) {
			const url = '/UserManagement/paymentHistory';
			let newData = (await axios.get(url)).data;
			setData(newData);
		}
	}

	if (!data || !isAuthenticated) {
		return <Spinner/>;
	}

	return (
		<React.Fragment>
			<h1>
				<img src={payments} alt={props.t("my-payments-title")} className="icon"/>
				{props.t('my-payments-title')}
			</h1>

			<div className="table-scroller">
				<Table className="data">
					<thead>
						<tr>
							<th>{props.t('my-payments-what')}</th>
							<th>{props.t('my-payments-sum')}</th>
							<th>{props.t('my-payments-when')}</th>							
						</tr>
					</thead>

					<tbody>
						{data.map((item, index) => renderRow(item, index))}
					</tbody>
				</Table>					
			</div>

			<SmileAndBuy/>
		</React.Fragment>
	)

	function renderRow(item, index) {
		return (
			<tr key={index} className="mypaymentsRow">
				<td>{props.t(`my-payments-${item.what}-count`, {count: item.quantity})}</td>
				<td className="sum">{item.total}€</td>
				<td>{new Date(item.when).toLocaleDateString()}</td>
			</tr>
		)
	}
}


export default withTranslation()(MyPayments);
