import React from 'react';
import { Context } from '../../../common/Context';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';


const Fav = (props) => {
	const { state: {serverAuthenticated} } = React.useContext(Context);
	const { isAuthenticated } = useAuth0();

	//initial values
	const { isLoading } = useQuery(['fav', props.name, isAuthenticated],  loadInitialValues, { refetchOnWindowFocus: false });

	const [isFavorite, setFavorite] = React.useState(false);
	const [isHidden, setHidden] = React.useState(false);

	React.useEffect(() => {
		if (isLoading || !serverAuthenticated) {
			return;
		}
		
		if (isFavorite && isHidden) {
			setHidden(false);
		}

		saveFavorite(isFavorite);
	}, [isFavorite, isLoading]);

	React.useEffect(() => {
		if (isLoading || !serverAuthenticated) {
			return;
		}
		
		if (isFavorite && isHidden) {
			setFavorite(false);
		}

		saveHidden(isHidden);
	}, [isHidden, isLoading]);	


	if (!serverAuthenticated || isLoading) {
		return null;
	}
	
	return (
		<React.Fragment>
			<i className={"icon-heart " + (isFavorite? 'selected' : '')} onClick={() => setFavorite(favorite => !favorite)}></i>
			<i className={"icon-block " + (isHidden? 'selected' : '')} onClick={() => setHidden(hidden => !hidden)}></i>
		</React.Fragment>
	)

	async function saveFavorite(favorite){
		const url = `/Students/Player/${props.name}/favorite`;
		await axios.post(url, {favorite});
	}

	async function saveHidden(hide){
		const url = `/Students/Player/${props.name}/hide`;
		await axios.post(url, {hide});		
	}

	async function loadInitialValues() {
		if (!isAuthenticated || !props.name) {
			return;
		}
		const favUrl = `/Students/Player/${props.name}/favorite`;
		let favorite = (await axios.get(favUrl)).data;
		setFavorite(favorite);

		const hiddenUrl = `/Students/Player/${props.name}/hidden`;
		let hidden = (await axios.get(hiddenUrl)).data;
		setHidden(hidden);

		return {favorite, hidden};	//probably don't need this
	}
}

export default Fav;
