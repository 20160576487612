import React from 'react';
import { Context } from '../../common/Context';
import { useQuery } from 'react-query';
import axios from 'axios';


const RedDot = ({screen}) => {
	const { state: {serverAuthenticated} } = React.useContext(Context);
	const { isSuccess, data:features } = useQuery(['reddot', serverAuthenticated], checkNewFeatures);

	if (!serverAuthenticated || !isSuccess || features.length === 0) {
		return null
	}

	const shouldShow = screen? features.includes(screen) : true;
	
	return shouldShow && <div className="redDot"/>

	async function checkNewFeatures(){
		if (!serverAuthenticated) {
			return null;
		}

		const url = '/UserManagement/Notifications/RedDot';
		const response = await axios.get(url);
		const features = response.data;
		return features;
	}
}

export default RedDot;
