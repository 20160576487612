import React from 'react';
import useProgressiveImg from '../../common/use_progressive_image';
import listener from './photo_fl_s_1.jpg';
import listenerWebP from './photo_fl_s_1.webp';


const Listener = (props) => {
	//progressive loading of the listener image
	const listenerBlurred = "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAGAAoDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAX/xAAiEAABAwQBBQEAAAAAAAAAAAABAgURAAMEIQYHEhNDUaH/xAAVAQEBAAAAAAAAAAAAAAAAAAADBP/EABsRAAIDAAMAAAAAAAAAAAAAAAIDAAEEERJB/9oADAMBAAIRAxEAPwA4OztxXqBjMdx9z8ry4HcldxCbnsWpQlWwNCNmPkaquefPySUjMBjUmygT+UpSY8iWByQyTbpaux635P/Z";
	const [listenerSrc, { blurred }] = useProgressiveImg(listenerBlurred, listenerWebP);
	
	return (
		<div id="listenerContainer" className="col-sm-8 col-xs-12">
			<img 
				src={listenerSrc} 
				id="listener" 
				style={{ filter: blurred? "blur(20px)" : "none", transition: blurred ? "none" : "filter 3s ease-out" }} />
		</div>

	)
}

export default Listener;
