import React from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from '../../../common/Context';
import DialogWrapper from '../../../common/DialogWrapper';
import axios from 'axios';

const STORAGE_KEY = "flyent_we-have-app_shown";
const link = "https://play.google.com/store/apps/details?id=online.flyent.twa";


const WeHaveAnAppPopup = () => {
	const [display, setDisplay] = React.useState(false);
	
	const { state } = React.useContext(Context);

	React.useEffect(() => { state.serverAuthenticated && showOrHide(); }, [state.serverAuthenticated]);

	if (!state.serverAuthenticated) {
		return null;
	}

	
	return (
		<DialogWrapper
			title="install-app-title" 
			show={display}
			cancel="DialogClose"
			onCancel={() => setDisplay(false)}
		>
			<WeHaveAnApp/>
		</DialogWrapper>
	)
	
	async function showOrHide(){
		const show = await shouldShow();
		if (show) {
			window.localStorage.setItem(STORAGE_KEY, true);
		}
		setDisplay(show);
	}

	async function shouldShow(){
		const isTWA = ('getDigitalGoodsService' in window);	
		if (isTWA) {
			return false;
		}
		
		const alreadyShown = !!window.localStorage.getItem(STORAGE_KEY);
		if (alreadyShown) {
			return false;
		}

		//check the # of played texts
		let response = await axios.get('/UserManagement/Credits/played');
		let played = parseInt(response.data);
		
		return (played >= 2);
	}
}

export default WeHaveAnAppPopup;

const WeHaveAnApp = () => {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<div style={{fontSize: '1.5rem', marginTop: '1em'}} >
				{t("install-app-description")}
			</div>

			<div style={{fontSize: '1.5rem', marginTop: '1em'}}>
				<a href={link}>{t("install-app-cta")}</a>
			</div>
		</React.Fragment>
	)
}

