import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import libo from './libosiara.svg';

class SmileAndStart extends Component {
    render() {
        return (
            <div className="smile-and-start">
                <img className="libo" src={libo} alt="try for free" />
                <span className="startButtonContainer">
                    <div className="vcenter">
                        {!this.props.preview && (
						<Link className="btn" to="/Students/Start" style={{verticalAlign: 'middle'}}>
                            <span className="try">{this.props.t("home-try")}</span>
							<span> </span>
                            <span className="free">{this.props.t("home-free")}</span>
                        </Link>)}
						
                        {this.props.preview && (
						<span className="btn" onClick={() => {this.props.setShowEmailDialog(true);}} style={{verticalAlign: 'middle'}}>
                            <span className="try">{this.props.t("home-try")}</span>
							<span> </span>
                            <span className="free">{this.props.t("home-free")}</span>
                        </span>)}
                    </div>
                </span>

            </div>
        )
    }
}

export default withTranslation()(SmileAndStart);