import React from 'react';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from '../../../common/ui/Spinner';
import BonusLink from './BonusLink';
import bonuses from './bonuses.svg';
import DialogWrapper from '../../../common/DialogWrapper';
import {Context} from '../../../common/Context';


const MyBonuses = (props) =>  {
	const [data, setData] = React.useState(null);
	const [linkVisible, setLinkVisible] = React.useState(false);
	const { user, isAuthenticated } = useAuth0();
	const {state} = React.useContext(Context);

	React.useEffect(() => { loadHistory(); }, [isAuthenticated, data]);


	if (!data || !isAuthenticated || !state.serverAuthenticated) {
		return <Spinner/>;
	}

	return (
		<React.Fragment>
			<h1>
				<img src={bonuses} alt={props.t("my-bonuses-title")} className="icon"/>
				{props.t('my-bonuses-title')}
			</h1>

			<div className="bonusesAre">({props.t('my-bonuses-are')})</div>

			<div className="table-scroller">
				<Table className="data">
					<thead>
						<tr>
							<th>{props.t('my-bonuses-what')}</th>
							<th>{props.t('my-bonuses-howmany')}</th>
						</tr>
					</thead>

					<tbody>
						{data.map((item, index) => renderRow(item, index))}
					</tbody>

				</Table>
			</div>

			<Table className="bonusHowTo">
				<thead>
					<tr>
						<th colSpan="2">
							{ReactHtmlParser(props.t('my-bonuses-getfree'))}
						</th>
					</tr>
				</thead>

				<tbody>
					<tr className="instructions">
						<td>
							<span className="circle yellow">1</span>
							{ReactHtmlParser(props.t('my-bonuses-bylistening'))}
						</td>
						<td>
							<span className="circle blue">2</span>
							{ReactHtmlParser(props.t('my-bonuses-byinviting'))}
						</td>
					</tr>
					<tr>
						<td className="cta yellow" onClick={() => {props.history.push('/Students/Start')}}>
							{props.t('my-bonuses-start')}!</td>
						<td className="cta blue" onClick={() => setLinkVisible(true)}>
							{props.t('my-bonuses-invite')}!</td>
					</tr>
				</tbody>
			</Table>

			<DialogWrapper show={linkVisible} onOK={() => setLinkVisible(false)} className="bonusLinkDialog" title="bonus-link-title">

				<div>
					{props.t('bonus-link-what')}
				</div>

				<div>
					{props.t('bonus-link-how')}
				</div>
				
				<BonusLink referralCode={state.serverUser.referralCode}/>					
			</DialogWrapper>
		</React.Fragment>
	)

	function renderRow(item, index){
		return(
			<tr key={index}>
				<td className="bonusWhat">{props.t('bonus-' + item.what)}</td>
				<td className="bonusCredits">+{item.credits}</td>
			</tr>			
		);
	}

	async function loadHistory() {
		if (isAuthenticated && !data) {
			const url = '/UserManagement/bonuses';
			let newData = (await axios.get(url)).data;
			setData(newData);
		}
	}

}


export default withTranslation()(MyBonuses);
