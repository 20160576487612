import React from 'react';
import { withRouter } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Context } from './Context';

const DialogWrapper = (props) => {

	const {state, dispatch} = React.useContext(Context);

	const [visible, setVisible] = React.useState(false);

	const { t } = useTranslation();

	React.useEffect(() => {setDialogVisibility(visible);}, [visible]);

	return (
		<Modal
			show={props.show && (visible || !state.dialogVisible)}	//don't show if another dialog is visible
			onEnter={() => onChangeVisibility(true)}
			onExit={() => onChangeVisibility(false)}
			className={props.className}>

			{props.title && (<Modal.Header>
				<Modal.Title>{t(props.title)}</Modal.Title>
			</Modal.Header>)}

			<Modal.Body>
				{props.children}
			</Modal.Body>

			{(props.onOK || props.cancel) && (<Modal.Footer>
				{props.onOK && (<Button className="btn btn-big btn-success" onClick={() => clickOK()}>
					{t(props.cta || "DialogClose")}
				</Button>)}

				{props.cancel && (
					<Button className="btn btn-big" onClick={() => clickCancel()}>
						{t(props.cancel)}
					</Button>
				)}

			</Modal.Footer>)}
		</Modal>
	)

	function clickOK() {
		if (props.onOK) {
			if (typeof props.onOK === 'string') {
				setVisible(false);
				props.history.push(props.onOK);
			} else {
				props.onOK();
			}
		}
	}

	function clickCancel() {
		if (props.onCancel) {
			if (typeof props.onCancel === 'string') {
				setVisible(false);
				props.history.push(props.onCancel);
			} else {
				props.onCancel();
			}
		}
	}

	function onChangeVisibility(visibility) {
		setDialogVisibility(visibility);	//notify others so that we don't open multiple dialogs
		setVisible(visibility);		//set the flag so that setDialogVisibility doesn't force this dialog to close
		if(visibility)
			window.analytics && window.analytics.track(`Dialog opened: ${props.title || props.className}`, {title: props.title});

	}

	function setDialogVisibility(dialogVisible){
		dispatch({
			type: 'DIALOG_VISIBLE',
			payload: { dialogVisible }
		});
	}
}

export default withRouter(DialogWrapper);
