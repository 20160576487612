import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import langData from '../../common/multilang';
import { useMutation } from 'react-query';
import { useLink } from 'valuelink/lib';


const SendEmail = (props) => {
	const { t, i18n } = useTranslation();
	const email = useLink('');
	const mutation = useMutation(newLead => axios.post('/UserManagement/Login/leads', newLead));

	if (mutation.isSuccess) {
		return (
			<div>{t('SendEmail-thankyou')}</div>
		)
	}

	return (
		<>
		<div className="sendEmailText">{t('SendEmail-text')}</div>

		<InputGroup>
			<FormControl type="email" placeholder="email" {...email.props} required={true} />
			<InputGroup.Button>
			<Button onClick={() => sendEmail()}>
				{getButtonContent()}
			</Button>
			</InputGroup.Button>
		</InputGroup>
		</>
	)

	function getButtonContent(){
		if (mutation.isLoading) {
			return (<> ... </>);
		}
		return (<> {t('SendEmail-cta', {lang: t(langData.nom)})} </>)
	}

	function sendEmail(){
		/* eslint-disable */
		window.analytics?.track("Lead left email");
		mutation.mutate({
			knownLang: i18n.language,
			learnedLang: langData.learnedLang,
			email: email.value
		});
	}
}

export default SendEmail;
