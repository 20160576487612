import React from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from '../../../common/Context';


const Filter = ({value}) => {
	const {dispatch} = React.useContext(Context);
	const selectedLevel = value.current;
	React.useEffect(() => {
		dispatch({action: 'FILTER', payload: {selectedLevel: selectedLevel}})
	}, [selectedLevel, dispatch]);
	
	const { t } = useTranslation();
	return (
		<div className="levelFilter">

			<div className="levelevel">
				{t('level')}:
			</div>
			
			<Option value={ value.equals( null ) } />
			<Option value={ value.equals( 0 ) } />
			<Option value={ value.equals( 1 ) } />
			<Option value={ value.equals( 2 ) } />
		</div>
	)
}

const Option = ({value}) => {
	let level = value.truthyValue;
	const { t } = useTranslation();
	if (level === null) {		//unfiltered
		let textDecoration = value.value? 'underline' : 'none';
		return <label style={{textDecoration}} onClick={() => {value.set(true)}}>{t('all')}</label>
	}

	return (
		<div className={"levelDigit levelDigit" + level + (value.value? " selected" : "")} onClick={() => {value.set(true)}}>
			{level}
		</div>
	);
}

export default Filter;
