import React from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './features/students/List/List';
import Player from './features/students/player/Player';
import Pay from './features/users/payments/Pay';
import Success from './features/users/payments/Success';
import Login from './features/users/Login';
import MyTexts from './features/users/history/MyTexts';
import MyBonuses from './features/users/history/MyBonuses';
import TeacherLink from './features/users/TeacherLink';
import MyPayments from './features/users/history/MyPayments';
import Support from './features/users/history/Support';
import Privacy from './features/pages/Privacy';
import Start from './features/students/List/Start';
import HowToDeleteUser from './features/pages/HowToDeleteUser';
import PayGoogle from './features/users/payments/PayGoogle';
import MySuccess from './features/users/history/MySuccess';
import MyReminders from './features/users/history/MyReminders';
import MyPhrases from './features/users/history/MyPhrases';

export default () => {
	return (
		<Switch>
			<Route path="/Students/Start" component={Start}/>
			<Route path="/Students/Player/:name" component={Player}/>
			<Route path="/Payments/Pay" component={Pay}/>
			<Route path="/Payments/PayGoogle" component={PayGoogle}/>
			<Route path="/Payments/Success" component={Success}/>
			<Route path="/User/Login" component={Login}/>
			<Route path='/User/MyTexts' component={MyTexts}/>
			<Route path='/User/MyBonuses' component={MyBonuses}/>
			<Route path='/User/TeacherLink' component={TeacherLink}/>
			<Route path='/User/MyPayments' component={MyPayments}/>
			<Route path='/User/MySuccess' component={MySuccess}/>
			<Route path='/User/MyReminders' component={MyReminders}/>
			<Route path='/User/MyPhrases' component={MyPhrases}/>
			<Route path='/User/Support' component={Support}/>
			<Route path="/Privacy" component={Privacy}/>
			<Route path="/HowToDeleteUser" component={HowToDeleteUser}/>
		</Switch>
	)
}
