import React from 'react';
import ReactDOM from 'react-dom';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
// import queryString from 'query-string';
import '@trendmicro/react-radio/dist/react-radio.css';
import { useLocalStorage } from "@rehooks/local-storage";
import { useQuery } from 'react-query';
import { Context } from '../../../common/Context';
// import {Helmet} from "react-helmet";
// import LevelAndChooser from './LevelAndChooser';
import Captions from './Captions';
import PlayerProgress from './PlayerProgress';
import PlayerControls from './PlayerControls';
import PhraseNavigation from './PhraseNavigation';
// import TranslationType from './TranslationType';
import Looper from './Looper';
import Stats from './Stats';
import HelpDialog from '../help/HelpDialog';
// import TopHeader from './TopHeader';

import './player.scss';
import TopHeader from './TopHeader';

import Excercise from './Excercise';
// import Explainer from './Explainer';
import DownloadText from './DownloadText';
// const auth = new Auth();
import Fav from './Fav';
import { useAuth0 } from '@auth0/auth0-react';


const Player = (props) => {

    const {state, dispatch} = React.useContext(Context);

    const [model, setModel] = React.useState();
	const [helpShown] = useLocalStorage('flyent_HelpShown');
	const [, setLastPlayed] = useLocalStorage('lastPlayed');
    //TODO: move all this to the store
    const [translationType] = React.useState('normal');
    const [playerState, setPlayerState] = React.useState('pause');
    const [playing, setPlaying] = React.useState(false);
    const [playTranslation, setPlayTranslation] = React.useState(false);
    const [duration, setDuration] = React.useState();
	const [showHelpDialog, setShowHelpDialog] = React.useState(!helpShown);
	
	const { isAuthenticated } = useAuth0();


    // while we're waiting for the AJAX call, let's fill the screen with the available data from the list
    React.useEffect(() => {
        if (!model && state.currentItem) {      //should get it from there to speed up rendering
            let item = state.currentItem;
            let modelFromProps = Object.assign({
                title: item.displayedTitle,
                moduleName: item.displayedModuleName,
                mediaPath: item.mediaPath,
                captions: { entries: [] }
            }, item.translation || item.contentItem);
            setModel(modelFromProps);
            setDuration(item.contentItem.duration);
        }
        dispatch({ type: 'PLAYER_INIT', payload: { forcedPosition: 0, forcedIndex: 0, currentItem: null }});   //init; reset current item just in case
    }, []);
    //model.duration = model.duration || (model.details && model.details.duration);


    //load the data
    const { isLoading, data:modelFromServer } = useQuery(['player', props.match, props.location.search],  () => {
		return getItemData();
    });
    
    React.useEffect(() => {
        if (modelFromServer) {
            setModel(modelFromServer);
            renderHeader(modelFromServer);
            setLangData(modelFromServer);
            setDuration(modelFromServer.details.duration);
			setLevel(modelFromServer);
            track(modelFromServer);
        }
    }, [isLoading, modelFromServer]);

    async function getItemData() {
        const match = props.match;
        const name = match.params.name;

		// make sure we have 'lang' here
		const searchParams = new URLSearchParams(props.location.search);
		ensureLang(searchParams);

        let url = `/Students/Player/data/${name}?` + searchParams.toString();
		let response = await axios.get(url);
        return response.data;
    }

	// if there's no lang param, get it from the global state
	function ensureLang(searchParams){
		let lang = searchParams.get('lang');

		if(!lang){
			lang = state.langData?.knownLang;
			searchParams.set('lang', lang);
		}		
	}


    //add a header to the topMenu
    function renderHeader(model){
        ReactDOM.render(<TopHeader model={model} history={props.history}/>, document.getElementById('moduleAndTitle'));
    }

    //read lang data from the model, save it to store and to local storage
    function setLangData(model){
        let langData = {
            learnedLang: model.details.lang,
            knownLang: model.lang,
            translatedOnly: true
        }
        setCurrentLanguagePair(langData);
        localStorage.setItem('langData', JSON.stringify(langData));
    }

	function setLevel(model){
		dispatch({action: 'FILTER', payload: {selectedLevel: model.details.level}});	//so that this levl is selected when we're back to the list
	}

    React.useEffect(() => {
		console.log("onPhraseChange", state.phraseIndex);

		if (state.phraseIndex === 0 && playing) {
			forceLogin();
        }

	}, [state.phraseIndex]);
	
	React.useEffect(() => {setLastPlayed(model?.name)}, [model]);


    if (!model) {
        return null;
    }


    return (
    <React.Fragment>

        {/* Helmet causes stack overflows because of circular calls in deepEqual */}
        {/* <Helmet>
            <title>Flyent | {model.title || model.name}</title>
        </Helmet> */}

						
		<div className="fav"><Fav {...model.details} /></div>

        <Captions
            hasTranslatedMedia={model.hasTranslatedMedia}
            setPlayTranslation={playTranslation => setPlayTranslation(playTranslation)}
            translationType={translationType}
            hasTranslation={model.normalTranslatedPhrases && model.normalTranslatedPhrases.length > 0}
            originals={model.captions.entries}
            literalTranslatedPhrases={model.literalTranslatedPhrases}
            normalTranslatedPhrases={model.normalTranslatedPhrases}
            lang={model.lang}
			model={model}
        />

        <PlayerProgress duration={duration}/>

        <div id="navigation">

            <Looper
                playerState={playerState}
            />


            <PlayerControls
                model={model}
                playerState={playerState}
                hasTranslatedMedia={model.hasTranslatedMedia}
                playTranslation={playTranslation}
                onStateChange={(eventName, playing) => { setPlayerState(eventName); setPlaying(playing); }}
            />

            <Stats
                itemName={model.name}
                lang={model.lang}
                duration={model.duration}
                playing={playing}
                playerState={playerState}
            />


        </div>

        {model.normalTranslatedPhrases && 
			<PhraseNavigation entries={model.captions.entries} normalTranslatedPhrases={model.normalTranslatedPhrases} />}

        {(model.details?.grammarDone && false) &&
            (<Excercise name={model.name} entries={model.captions.entries}/>)}

        {/* Download the transcription */}
        { isAuthenticated && (<DownloadText model={model}/>)}
		

        <HelpDialog show={showHelpDialog} onClose={ () => { setShowHelpDialog(false); } }/>

        {/* <Explainer/> */}

    </React.Fragment>
    )

    // componentWillUnmount(){
    //     //remove the header in the topMenu
    //     ReactDOM.unmountComponentAtNode(document.getElementById('moduleAndTitle'));
    // }

    function track(model){
        window.analytics && window.analytics.track("Player opened",{
            name: model.name,
			learnedLang: model.details.lang,
            knownLang: model.lang,
            lang: model.lang 	//backwards compat (Klaviyo)
        });
	}

	function setCurrentLanguagePair(langData){
		dispatch({
			type: 'SELECTED_LANG',
			payload: { langData }
		});
	}

	function forceLogin(){
		dispatch({
			type: 'FORCE_LOGIN',
			payload: {forceLogin: true}
		});
	}

}




export default withTranslation()(Player);
