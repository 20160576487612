import React from 'react';
import { Link } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import { Alert } from 'react-bootstrap';
import NoNeed from './NoNeed';
import SmileAndStart from "./SmileAndStart";
import TwoSteps from './TwoSteps';
import AlreadyAndTeacher from './AlreadyAndTeacher';
import Vera from './Vera';
import Difference from './Difference';
import Localizer from './Localizer';
import './home.scss';
import '../../common/ui/fontello/css/fontello.css';
import logo from '../../common/ui/images/logo.png';
import libo from './libosiara.svg';
import DialogWrapper from '../../common/DialogWrapper';
import BonusHelp from './BonusHelp';
import References from './References';
import queryString from 'query-string';
import langData from '../../common/multilang';
import SendEmail from './SendEmail';
import TopStartButton from './TopStartButton';
import Listener from './Listener';
import useAB from '../../common/useAB';

const Home = (props) => {

	const [showPricing, setShowPricing] = React.useState(false);
	const [hideAlert, setHideAlert] = React.useState(true);
	const [showEmailDialog, setShowEmailDialog] = React.useState(false);

	React.useEffect(() => { fetch('/ping'); }, []);	//prewarm
	React.useEffect(() => { isReturningUser() && redirectToListScreen(); }, [redirectToListScreen]);	//redirect returning users

	const { t } = useTranslation(['translation', 'translation-B']);
	// const h2AB = useAB('flyent_home_AB');
	// const h2namespace = (h2AB === 'A')? 'translation' : `translation-${h2AB}`;

	const preview = useQueryString('preview', false);

	return (
		<React.Fragment>

			<div style={{position: 'relative', top: '0vh'}} className='home'>
				<div id="navbar" className="navbar navbar-default navbar-static-top" itemScope itemType="http://schema.org/WPHeader">
					<nav className="container vcenter"  itemScope itemType="http://schema.org/SiteNavigationElement">
						<div className="navbar-header" >
							<img id="logo" src={logo} />

							<div className="localizer">
								<Localizer/>
							</div>
						</div>

						{/* <div id="topMenu" className="vcenter">
							<ul className="nav">
								<li ng-click="displayAbout()">
									how to use
								</li>
							</ul>
						</div> */}

					</nav>
				</div>

				<div id="firstStrip" ></div>

				<div id="mainContainer">
					{!hideAlert && (
						<Alert onDismiss={() => { setHideAlert(true); }} id="badDesignAlert" >
							{t('home-bad-design')}
						</Alert>
					)}

					<div id="middleSloganContainer" className="vcenter">
						<h1>{ReactHtmlParser(t('slogan', {lang: t(langData.nom)}))}</h1>
					</div>

					<div id="listenerAndMethod" className="row">
						<Listener/>

						<div id="lowerPart" className="col-sm-4">
							<div>
								<img className="libo" src={libo} />

								<span id="aboutTheMethod">
									{ReactHtmlParser(t(`slogan2`))}
								</span>
							</div>

							<TopStartButton preview={preview} setShowEmailDialog={setShowEmailDialog}/>

						</div>
					</div>

					<NoNeed/>

					<TwoSteps preview={preview} setShowEmailDialog={setShowEmailDialog}/>

					{!preview &&
						(<References/>)}

					<AlreadyAndTeacher/>

					<Vera/>

					<Difference/>

					<div className="lastCTA">
						<SmileAndStart preview={preview} setShowEmailDialog={setShowEmailDialog}/>
					</div>

					<div className="bonus-description">
						<span>{t('home-bonus-description')}</span>
						<span onClick={() => setShowPricing(true)} className="bonus-help-link">{t('home-bonus-description-how')}</span>
					</div>

					<div className="footer">
						<div>
							&copy; 2019 MB Satoji Kaida
						</div>

						<div>
							Contact: team&#64;flyent.online
						</div>

						<div>
							<Link to="/Privacy">Privacy Policy</Link>
						</div>

						{/* <Chat/> */}
					</div>

				</div>

			</div>

			<DialogWrapper title="BonusHelp-title" show={showPricing} onOK={() => setShowPricing(false)}>
				<BonusHelp/>
			</DialogWrapper>

			<DialogWrapper title="SendEmail-title" show={showEmailDialog} onOK={() => setShowEmailDialog(false)} className="sendEmailDialog">
				<SendEmail/>
			</DialogWrapper>

		</React.Fragment>
	)

	function isReturningUser() {
		let helpShown = localStorage.getItem('flyent_HelpShown');
		return !!helpShown;
	}

	function redirectToListScreen() {
		let hash = window.location.hash.substring(1);
		if (hash !== "stay") {
			props.history.push('/Students/Start');		
		}

	}

	function useQueryString(name, defaultValue){
		let params = queryString.parse(window.location.search);
		return params[name] || defaultValue;
	}
}

export default Home;
