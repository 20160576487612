import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';
import axios from 'axios';
import { useQuery } from 'react-query';
import { Context } from '../../../common/Context';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import numeral from 'numeral';

momentDurationFormatSetup(moment);

require('numeral/locales');		//to load all locales
//fix the ru ordinal
numeral.locales.ru.ordinal = num => "";


const MySuccess = () => {
	
	const { state } = React.useContext(Context);
	const { t, i18n } = useTranslation();
	const { isSuccess, data } = useQuery(['success', state.serverAuthenticated], loadData);

	numeral.locale(i18n.language);

	if (!isSuccess || !state.serverAuthenticated) {
		return null;
	}

	//data
    const daily = data?.daily.map(record => {
		record.hours = record.usageInSeconds / 3600.00;
		return record;
	});
	let xticks = daily.map(item => item.date);
	const yticks = getHourTicks(daily);
	const thisWeek = formatTime(data.thisWeek);
	// const lastWeek = formatTime(data.lastWeek);
	const improvementPercentage = data.improvement && Math.round(100 * (data.improvement - 1));
	const timeToNext = data.next && formatTime(data.next.total - data.thisWeek);
	  
	return (
		<React.Fragment>
			
			<h1>{t('my-success-title')}</h1>

			<table className="table">
				<thead>
					<tr>
						<th>{t('my-success-used')}</th>
					</tr>
				</thead>

				<tbody>
					<tr>
						<td>

							{!!data.thisWeek && <>

								{data.myPlace && <div>
									<span className="successPrimary">
										{numeral(data.myPlace).format('0o')}!</span> {t('my-success-place', {userCount: data.userCount})} 
									{data.next && (
										<div className="successSecondary">
											{t('my-success-place-next', {timeToNext, place: data.next.place})}
										</div>
									)}
								</div>}

								<div style={{marginTop: '1em'}} >
									<span className="successPrimary">{thisWeek}</span> {t('my-success-week-learning')}   
										{data.improvement && 
											<span className="successSecondary">({getIcon(improvementPercentage)}{improvementPercentage}% {t('my-success-compared')})</span>} 

								</div>

								<div style={{marginTop: '1em'}}>
									<span className="successPrimary">{data?.streak}</span> {t('my-success-row')}
								</div>

							</>}

						</td>
					</tr>

				</tbody>
			</table>
			
			<LineChart data={daily} className="weeklyStats" width={400} height={300}>
				<XAxis dataKey="date" ticks={xticks} tickFormatter={formatDateTick} />
				<YAxis ticks={yticks} unit="h" />
				<Line type="monotone" dataKey="hours" stroke="#86ac9f" dot={{ stroke: 'none', r: 4, fill: '#86ac9f'}}  activeDot={{r: 8}}/>
				<Tooltip labelFormatter={formatDateTick} formatter={(value, name, props) => [formatTime(props.payload.usageInSeconds), null]} />
			</LineChart>
			
		</React.Fragment>
	)

	async function loadData(){
		if (!state.serverAuthenticated) {
			return null;
		}
		const response = await axios.get('/Students/Stats/Rating');
		return response.data;
	}

	function getIcon(percentage){
		if (percentage > 0) {
			return "⬆";
		}
		if (percentage < 0) {
			return "⬇";
		}
		return "=";
	}

	//get the ticks for the Y axis, including the max hour
	function getHourTicks(daily){
		const hours = daily.map(record => record.hours);
		const maxDuration = Math.max(...hours);
		const maxHour = Math.ceil(maxDuration) || 1;
		return Array.from({length: maxHour + 1}, (_, i) => i);
	}

	function formatTime(timeInSeconds){
		if (timeInSeconds) {
			return moment.duration(timeInSeconds, "seconds").format("h[h] m[m] s[s]", {
				trim: "both"
			});
		} else 
			return null;
	}

	function formatDateTick(value){
		return moment(value).format("Do MMM");
	}
}

export default MySuccess;


