import React from 'react';
import axios from 'axios';
import './App.css';

// import { Router } from "react-router";
import { createBrowserHistory } from "history";
import { Route, Switch, Router } from 'react-router-dom';

import Home from './features/home/home';
import MainLayout from './common/MainLayout';

// import Auth from './features/users/auth';

import loadLanguages from './common/localization';

import Analytics from "./common/tools/Analytics";
import AutoUpdate from './common/AutoUpdate';
import Auth from './features/users/Auth';
import ShouldPayV2 from './features/users/payments/alerts/ShouldPayV2';
import AskForPush from './features/users/AskForPush';
import { Auth0Provider } from '@auth0/auth0-react';
import queryString from 'query-string';
import { Context } from './common/Context';
import {
	useQuery,
	useMutation,
	useQueryClient,
	QueryClient,
	QueryClientProvider,
  } from 'react-query';

//initialization
axios.interceptors.request.use(request => {
	request.headers = request.headers || {};
	request.headers['X-Requested-With'] = 'XMLHttpRequest';
	return request;
});


const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false
		}
	}
});

const App = (props) => {


	const [localized, setLocalized] = React.useState(false);

	const {state, dispatch} = React.useContext(Context);

	const historyContainer = React.useRef(null);
	const createHistoryLazily = () => {
		return historyContainer.current = historyContainer.current || createBrowserHistory();
	}
	const history = createHistoryLazily();

	React.useEffect(() => { localize(); }, []);

	if (!localized) {
		return null;
	}

	// console.log("Rerendering App");

	return (
		<Router history={history}>
			<React.Fragment>
				<Auth0Provider
					domain="flyent.eu.auth0.com"
					clientId="3E-8GqIgvil8qfMr25U5nEaEBK3uNPfB"
					redirectUri={window.location.origin}
					useRefreshTokens={true}
					cacheLocation="localstorage"
					onRedirectCallback={appState => historyContainer.current.push(appState.returnTo)}
				>
					<QueryClientProvider client={queryClient}>
						<Auth/>
						<Analytics/>
						<AutoUpdate/>
						<ShouldPayV2/>
						<AskForPush/>
						<Switch>
							<Route render={props => {
								if (isAuthRedirect()) {
									return null;
								}
								return(
									<Switch>
										<Route path="/" exact component={Home}/>
										<Route path="/" component={MainLayout}/>
									</Switch>
								)						
							}}/>						
						</Switch>					
					</QueryClientProvider>

				</Auth0Provider>
			</React.Fragment>
		</Router>
	);

	async function localize(){
		await loadLanguages(dispatch);
		setLocalized(true);
	}

	function isAuthRedirect() {
		let params = queryString.parse(window.location.search);
		return !!params.state;
	}
}



export default App;
