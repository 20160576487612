import React from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from '../../../common/Context';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocalStorage } from "@rehooks/local-storage";
import DialogWrapper from '../../../common/DialogWrapper';
import axios from 'axios';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import cachedData from './first_visit_data.json';


const FirstVisit = () => {
	const { isAuthenticated, isLoading } = useAuth0();
	const [langDataFromStorage] = useLocalStorage('langData');
	const [helpShown] = useLocalStorage('flyent_HelpShown');

	const [show, setShow] = React.useState(true);

	if (isLoading || !shouldShow()) {
		return null;
	}

	return (
		<DialogWrapper show={shouldShow() && show} title="FirstVisit-title" className="firstVisitDialog">
			<FirstVisitContent close={() => setShow(false)}/>
		</DialogWrapper>
	)

	function shouldShow(){
		if (queryString.parse(window.location.search)?.first) {	//for testing, use ?first=true
			return true;
		}
		return !isAuthenticated && (!langDataFromStorage || !helpShown);
	}

}

export default FirstVisit;

function FirstVisitContent(props) {
	const { state: {langData} } = React.useContext(Context);
	const { isLoading, data:serverItems } = useQuery(['items-top', langData],  loadItems, { refetchOnWindowFocus: false });
	const cachedItems = cachedData[langData.learnedLang][langData.knownLang];
	const items = serverItems || cachedItems;

	const { t } = useTranslation();

	if (!items) return null;


	return (
		<div className="firstVisit">
			<Link to={`/Students/Player/${encodeURI(items.zeroLevelItem.name)}?lang=${langData.knownLang}`} className="firstVisitButton" onClick={() => trackLevelClick('zero')}>
				{t('FirstVisit-zeroLevel')}
			</Link>

			<Link to={`/Students/Player/${encodeURI(items.someLevelItem.name)}?lang=${langData.knownLang}`} className="firstVisitButton" onClick={() => trackLevelClick('some')}>
				{t('FirstVisit-someLevel')}
			</Link>

			<a onClick={() => { trackLevelClick('list'); props.close(); }} className="firstVisitButton secondary">
				{t('FirstVisit-browse')}
			</a>

		</div>
	);
	
	async function loadItems(){
		let url = "/Students/List/items/top?" + queryString.stringify(langData);
		let response = await axios.get(url, langData);
		return response.data;		
	}

	function trackLevelClick(level) {
		/* eslint-disable */
		window.analytics?.track("First Visit", { level });
	}
}
