import React from 'react';
import { Context } from '../../../common/Context';
import Fav from './Fav';
import Bookmark from './Bookmark';

const Captions = (props) => {

    const {state, dispatch} = React.useContext(Context);
	const [playTranslation, setPlayTranslation] = React.useState(false);

	let captionsNotLoaded = props.originals.length === 0;
	if (captionsNotLoaded || (state.phraseIndex === undefined)) {
		return (
			<div id="originalAndTranslated">
				<div id="original" className="caption vcenter"></div>

				<div id="translations">
					<div id="textSeparator"></div>
					<div id="translation_normal" className="caption"></div>
				</div>
			</div>
		)
	}

	// console.log(this.props);
	let phraseIndex = state.phraseIndex;
	//if the first text is empty, let's move it forward
	if (phraseIndex === 0) {
		while (props.originals[phraseIndex].text === '') {
			phraseIndex++;
		}
	}

	let original = props.originals[phraseIndex].text;
	let translation_normal = props.hasTranslation && props.normalTranslatedPhrases[phraseIndex];
	let translation_literal = props.hasTranslation && props.literalTranslatedPhrases[phraseIndex];
	let phraseId = props.originals[phraseIndex].phraseId;
	return (


		<div id="originalAndTranslated">

			<div id="original" className="caption">
				<span>
					{original}
				</span>
			</div>

			{props.hasTranslation &&
				<div id="translations" style={{position: 'relative'}}>
					<div id="textSeparator"></div>

					{props.translationType === 'normal' &&
						<div id="translation_normal" className={`caption lang-${props.lang}`}>
							<span>{translation_normal}</span>
						</div>
					}

					{props.translationType === 'literal' &&
					<div id="translation_literal" className="caption">
						<span>{translation_literal}</span>
					</div>
					}
				</div>
			}


			<div className="bookAndPlay">
				<Bookmark phraseId={phraseId}/>

				{(props.hasTranslatedMedia) && (
					<button className="btn btn-sm player-btn" id="translation_player" onClick={() => togglePlayTranslation()} style={{opacity: playTranslation? '1' : '0.5'}}>
						<i className="icon-sound"></i>
					</button>
				)}
			</div>

		</div>
	)

    function togglePlayTranslation(){
        props.setPlayTranslation(!playTranslation);
        setPlayTranslation(!playTranslation);
    }
}

export default Captions;
