import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useLink } from 'valuelink';
import { useQuery, useMutation } from 'react-query';
import moment from 'moment';
import { Context } from '../../../common/Context';
import axios from 'axios';
import Spinner from '../../../common/ui/Spinner';
import { ControlLabel, FormControl } from 'react-bootstrap';
import AddToCalendar from 'react-add-to-calendar-recurring';
import 'react-add-to-calendar-recurring/dist/react-add-to-calendar-recurring.css';
import useSetVisited from '../dot_visited';

const MyReminders = () => {
	const { state } = React.useContext(Context);
	const { t } = useTranslation();
	const { isLoading, isSuccess } = useQuery(['reminders', state.serverAuthenticated], loadData, { refetchOnWindowFocus: false });
	const mutation = useMutation(selectedList => axios.post('/UserManagement/reminders', selectedList));

	const daysLink = useLink();
	const timeLink = useLink();

	React.useEffect(() => {
		console.log(`mutation.mutate: isLoading: ${isLoading}, isSuccess: ${isSuccess}, days: ${daysLink.value}`);
		// debugger;
		if (isSuccess) {	//first save should happen only after first load
			mutation.mutate({days: daysLink.value, time: timeLink.value});
		}
		
	}, [daysLink.value, timeLink.value]);

	useSetVisited('reminders');

	if(isLoading || !state.serverAuthenticated)
		return <Spinner/>

	const days = '1234567'.split('').map(dayNum => <Day dayNum={parseInt(dayNum)} daysLink={daysLink} key={dayNum} /> )

	console.log(`rendering: isLoading: ${isLoading}, isSuccess: ${isSuccess}, days: ${daysLink.value}`);
	const event = getEventData();
	let icon = { 'calendar-plus-o': 'left' };

	const allSelected = (daysLink.value.length === 7);

	return (
		<React.Fragment>
			<h1>{t('my-reminders-title')}</h1>

			<div className='text' style={{margin: '1.5em auto', maxWidth: '18em'}} >
				<div style={{fontSize: '1.8rem'}}>{t('my-reminders-set')}</div> 
				<ul>
					<li>{t('my-reminders-set-1')}</li>
					<li>{t('my-reminders-set-2')}</li>
					<li>{t('my-reminders-set-3')}</li>
				</ul>
			</div>

			<div className="remindersDay">
				{days}

				{allSelected? 
					<Uncheck onClick={() => { daysLink.set([]); }}/>:
					<Check onClick={() => { daysLink.set([1,2,3,4,5,6,7]); }}/>
					}
				
			</div>

			<div className="remindersTime">
				<ControlLabel>{t('my-reminders-time')}</ControlLabel>
				<FormControl
					type="text"
					value={timeLink.value || undefined}
					placeholder="hh:mm"
					onChange={timeLink.props.onChange}
				/>				
			</div>

			<div className="remindersButton">
				<AddToCalendar
					event={event}
					buttonLabel={t('my-reminders-cta')}
					buttonTemplate={icon} />
			</div>

			<div className='smaller-text'>{t('my-reminders-note')}</div>

		</React.Fragment>
	)

	async function loadData(){
		console.log("loadData")
		if (!state.serverAuthenticated) {
			console.log("!state.serverAuthenticated");
			return null;
		}
		var url = '/UserManagement/reminders';
		const response = await axios.get(url);
		console.log("Setting the values");
		daysLink.set(response.data.days);
		timeLink.set(response.data.time);
		console.log("loadData finished");
		return response.data;		
	}

	function getEventData() {
		return {
			title: 'Learn Lithuanian',
			description: 'Learn Lithuanian using <a href="https://flyent.online">flyent</a>',
			location: 'online, https://flyent.online',
			startTime: eventStart(),
			// endTime: '2016-09-16T21:45:00-04:00',
			recurring: {
			   repeat: 'weekly', /* or DaiLy, Monthly, Yearly */
			   byDay: selectedDaysEn(), /* SU,MO,TU,WE,TH,FR,SA */
			   /* or byMonth: 1 (number) */
			   interval: 1, /* Default is 1 */
			//    weekStart: 'MO', /* Week start default is Sunday */
			//    count: 10, /* Weekly for 10 occurrences */
			}
		};
	}

	function selectedDaysEn(){
		return daysLink.value.map(dayNum => {
			let dayName = moment().day(dayNum).toDate().toLocaleDateString('en-GB', { weekday: 'short' });
			return dayName.substr(0, 2).toUpperCase();
		}).join(',');
	}

	function eventStart(){
		let start = moment();
		var x = moment("07:00", "hh:mm").isValid();
		if(timeLink.value){
			start = moment(timeLink.value, "hh:mm");
		}
		return start.toDate().toISOString();
	}
}

export default MyReminders;

const Day = ({ dayNum, daysLink }) => {
	const { i18n } = useTranslation();
	const dayName = moment().day(dayNum).toDate().toLocaleDateString(i18n.language, { weekday: 'short' });
	const selected = daysLink.value.includes(dayNum);
	return <div className={`reminderDay ${selected? "selected" : ""}`} onClick={toggle}>{dayName}</div>;

	function toggle() {
		const selectedList = daysLink.value;
		let selected = selectedList.includes(dayNum);
		if (selected) {
			selectedList.splice(selectedList.indexOf(dayNum), 1);
		} else {
			selectedList.push(dayNum);
		}

		daysLink.set([...selectedList]);
	}
}

const Check = props => 
	<div className="remindersSelector">
		<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
		</svg>
	</div>

const Uncheck = props => 	
	<div className="remindersSelector">
		<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
		</svg>
	</div>