import axios from 'axios';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';


export default async function useSetVisited(screenName){
	const queryClient = useQueryClient();
	const mutation = useMutation(() => {
		const url = '/UserManagement/Notifications/RedDot';
		return axios.post(url, {screenName});
	}, {
		onSuccess: () => queryClient.invalidateQueries('reddot')
	});
	
	React.useEffect(() => { 
		mutation.mutate(); 
	}, []);
}