import React from 'react';
import FirstVisit from './FirstVisit';
import List from './List';


const Start = (props) => {
	return ([
		<FirstVisit key={0}/>,
		<List key={1} />
	]);
}

export default Start;
