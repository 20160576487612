import React from 'react';
const Reducer = (state, action) => ({ ...state, ...action.payload });
// const Reducer = (state, action) => {
// 	debugger;
// 	return { ...state, ...action.payload }
// }

const initialState = {
	playedPosition: 0
};

export const Context = React.createContext(initialState);

const Store = ( {children} ) => {
	const [state, dispatch] = React.useReducer(Reducer, initialState);

	return (
		<Context.Provider value={{state, dispatch}}>
			{children}
		</Context.Provider>
	)
}

export default Store;
