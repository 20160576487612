import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import support from './support.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import MessengerCustomerChat from 'react-messenger-customer-chat/lib/MessengerCustomerChat';
import { useAuth0 } from '@auth0/auth0-react';


const Support = (props) => {
	const [text, setText] = useState('');
	const [status, setStatus] = useState('');
	const { isAuthenticated } = useAuth0();

	if(!isAuthenticated)
		return null;

	return (
		<React.Fragment>
			<h1>
				<img src={support} alt={props.t("support-title")} className="icon"/>
				{props.t("support-title")}
			</h1>

			<div className="what userPageText">{props.t("support-what")}:</div>

			<textarea value={text} onChange={e => handleChange(e)}></textarea>

			<div className="sendWrapper">
				<span className={"send " + getButtonClass()} onClick={() => sendFeedback()}>
					{(status === "sent")? props.t("support-sent") : props.t("support-send")}
					{(status === "sent") && 
						<FontAwesomeIcon icon={faCheck} className="icon"/>
					}
				</span>
			</div>

			<MessengerCustomerChat
				pageId="104278414576522"
				appId="585050435372865"
				themeColor="#86ac9f"
				loggedInGreeting="Здравствуйте! Чем мы можем Вам помочь?"
				loggedOutGreeting="Здравствуйте! Чем мы можем Вам помочь?"
				language="ru_RU"
				version="7.0"
			/>

		</React.Fragment>
	)

	function handleChange(event){
		setText(event.target.value);
	}

	async function sendFeedback() {
		const url = '/UserManagement/support';
		setStatus("sending");
		await axios.post(url, {text: text});
		setStatus("sent");
	}

	function getButtonClass() {
		return status === "sending"? 'disabled' : '';
	}
}

export default withTranslation()(Support);
