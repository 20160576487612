import React from 'react';
import { useTranslation } from 'react-i18next';
//import { Context } from '../../../common/Context';
import { useAuth0 } from '@auth0/auth0-react';


const HowToDeleteUser = (props) => {

	return (
		<React.Fragment>
			<h1>How to delete your personal data</h1>

			<p>To delete your personal data, please send a request for deletion to team@flyent.online.
				Your personal data will be deleted within 24h.
			</p>
		</React.Fragment>
	)
}

export default HowToDeleteUser;
