import React from 'react';
import axios from 'axios';
import store from '../../common/store';
import queryString from 'query-string';
import { useAuth0 } from "@auth0/auth0-react";
import { Context } from '../../common/Context';
import useAB, { getAll } from '../../common/useAB';


const Auth = () => {

	// console.log("Auth rerender");
	const {state, dispatch} = React.useContext(Context);


	//initial setup for AJAX 401 interceptors
	React.useEffect(() => { interceptUnauthResponses(); }, []);

	const { isLoading, getAccessTokenSilently, loginWithRedirect, logout, user, isAuthenticated, getIdTokenClaims } = useAuth0();

	//login
	React.useEffect(() => { !isLoading && login(!!state.forceLogin || existingUser()); }, [state.forceLogin, isLoading, isAuthenticated]);

	//save coupon
	React.useEffect(() => saveCouponToStore(), []);

	//save referrer to the local storage
	React.useEffect(() => {getReferrer();}, []);

	//tracking some weird error
	const ref = React.useRef(0);


	return null;

	async function login(force) {
		ref.current = Math.random();
		console.log(ref.current, `Force login: ${force}, isAuthenticated: ${isAuthenticated}, serverAuthenticated: ${!!state.serverAuthenticated}`);

		let token = null;

		try {
			token = await getAccessTokenSilently({
				ignoreCache: false,
				scope: 'openid'
			});
			console.log(ref.current, `Token: ${token}`);		
		} catch (error) {
			console.error(error);
		}


		
		let claims = await getIdTokenClaims();
		let idToken = claims?.__raw;
        //if we already authenticated during this session (after the last page reload)
        if (isAuthenticated || idToken) {			
			console.log(ref.current, "Server login...");
			let dbData = await serverLogin(idToken);
			dispatch({ type: 'SERVER_LOGIN', payload: {serverAuthenticated: true} });
			console.log(ref.current, "Server authenticated");

			// only track the user if logged in clientside
			if (isAuthenticated) {
				await trackUser(dbData);	//we need user != null here
			}
			
			redirectIfNeeded();

        } else if(force && !token){	//sometimes isAuthenticated is not set yet, but token is there already
			console.error("Forced login");
			onLoginDialogOpen();
			//await loginWithPopup({screen_hint: "signup"});
			const coupon = getCoupon() || state.coupon;		//for coupon, look in the URL then in the saved state
			const returnUrl = buildReturnUrl(coupon);
			debugger;

			await loginWithRedirect({
				appState: {returnTo: returnUrl},
				display: 'page',
				screen_hint: "signup",
				redirect_uri: `${window.location.origin}/Students/Start`,
				max_age: 7776000
			})
		}

		function buildReturnUrl(coupon) {
			let params = queryString.parse(window.location.search);
			if (coupon) {
				params.ref = coupon;
			}
			return `${window.location.pathname}?${queryString.stringify(params)}`;
		}
	}

	async function serverLogin(idToken){
		const coupon = getCoupon();
		const referrer = getReferrer();
		let allAB = getAll();
		let loginResponse = await axios.post('/UserManagement/Login', {idToken, coupon, referrer, allAB});
		if (!loginResponse.data) {
			debugger;
			throw new Error("/UserManagement/Login returned null");
		}
		return loginResponse.data;	//the server-side user record
	}

	function redirectIfNeeded() {
		let params = queryString.parse(window.location.search);
		let returnUrl = params.returnUrl;
		if (returnUrl) {
			window.location.assign(returnUrl);
		} 	
	}

	//detect existing user
	function existingUser(){
		return localStorage.getItem('userInfo') || localStorage.getItem('ajs_user_id');
	}
	

	//analytics
	async function trackUser(dbData){
		let userInfo = Object.assign(user, dbData);
		userInfo.uses_version = process.env.REACT_APP_BUILD_TIME;
		localStorage.setItem('userInfo', JSON.stringify(userInfo));
		/* eslint-disable */
		window.analytics?.identify(userInfo.nickname, userInfo);
		dispatch({ type: 'SERVER_USER', payload: {serverUser: userInfo} });
		await axios.post('/UserManagement/Login/add_to_list');
	}


	function getReferrer() {
		let referrer = localStorage.getItem('referrer');
		if (referrer) {
			return referrer;
		}

		if (!document.referrer.startsWith(window.location.origin)) {
			referrer = document.referrer;
		}
		referrer = referrer || "(unknown)";
		localStorage.setItem('referrer', referrer);
		return referrer;
	}
	
	function getCoupon() {
		let params = queryString.parse(window.location.search);
		if (params.ref) 
			return params.ref;
		
		//if we are redirected from the Teacher Zone, the coupon might be hidden in the return Url
		if (params.returnUrl) {
			let url = window.location.origin + params.returnUrl;	//full return url
			let search = new URL(url).search;
			params = queryString.parse(search);
			if (params.ref) 
				return params.ref;
		}
	}

	function saveCouponToStore() {
		const coupon = getCoupon();
		if (coupon) {
			dispatch({ type: 'COUPON', payload: {coupon} });
		}
	}

	//tell the others that we opened a dialog (needed to stop playback)
	function onLoginDialogOpen(){
		// window.dispatchEvent(new CustomEvent("dialog_show"));
		/* eslint-disable */
		window.analytics?.track("Registration dialog");
		store.dispatch({ type: 'DIALOG_VISIBLE', payload: { dialogVisible: true } });
	}
	
	
	//make sure we're server-authenticated, meaning we have a valid auth cookie
	async function ensureServerAuthenticated() {
		await axios.get('/UserManagement/Login/force');
	}


	function interceptUnauthResponses(){
        axios.interceptors.response.use(null, async function (error) {
            let errorMessage = error.message;
			let response = error.response;
            if(response && response.status === 401){
				console.error(`Error 401 from server, client isAuthenticated: ${isAuthenticated}`);                
				// logout({localOnly: true});	//in case we have logged out from server, but client can relogin us automatically
                await login(true);
            }

            return Promise.reject(error);
		});
	}
}


export default Auth;
