import React from 'react';
//import { Context } from '../../../common/Context';


const Bubbles = ({numBubbles}) => {
	//const { state, dispatch } = React.useContext(Context);

	// const firstRender = useFirstRender();
	useEffectOnNextRender(createBubbles, [numBubbles])

	const [totalBubbles, setTotalBubbles] = React.useState(0);
	const bubbles = [];
	for (let index = 0; index < totalBubbles; index++) {
		bubbles.push(<Bubble key={index} numBubbles={numBubbles} />)
	}


	
	return (
		<React.Fragment>
			{bubbles}
		</React.Fragment>
	);

	function createBubbles() {
		setTotalBubbles(val => val + numBubbles);
	}

	async function wait(timeInMs){
		return new Promise(resolve => window.setTimeout(resolve, timeInMs));
	}
}

function useFirstRender() {
	const firstRender = React.useRef(true);
  
	React.useEffect(() => {
	  firstRender.current = false;
	}, []);
  
	return firstRender.current;
}

function useEffectOnNextRender(fn, deps) {
	const firstRender = React.useRef(true);
	React.useEffect(() => {
		if (!firstRender.current) {
			fn();
		}
		firstRender.current = false;
	}, deps);
}

const Bubble = ({numBubbles}) => {
	const [visible, setVisible] = React.useState(false);	//hide initially; show after a random period
	React.useEffect(() => {		
		let delay = Math.random() * 1000;
		window.setTimeout(() => setVisible(true), delay);
	}, []);

	const [bubbleClass, setBubbleClass] = React.useState('');
	const [shiftX, setShiftX] = React.useState(-0.5);	//initial shift to compensate for the initial size

	React.useEffect(() => {
		if (visible) {
			window.setTimeout(() => {
				setBubbleClass('up');
				setShiftX(Math.random() * numBubbles * 2 - numBubbles - 1);
			}, 10);

			//after 5s, hide them so that they don't mess up with other buttons
			window.setTimeout(() => {
				setBubbleClass('up hidden');
			}, 5000);
		}
	}, [visible]);

	let wrapperStyle = {transform: `translate(${shiftX}em, 0)`};
	let [hue] = React.useState(Math.floor(Math.random() * 360));

	let bubbleStyle = {backgroundImage: `radial-gradient(hsl(${hue}, 50%, 50%, 0), hsl(${hue}, 50%, 50%))`};
	return (visible && <div className={"bubbleWrapper "+ bubbleClass} style={wrapperStyle} >
		<div className={"bubble " + bubbleClass} style={bubbleStyle} />
	</div>)
}

export default Bubbles;
