import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import './Pay.scss';
import Spinner from '../../../common/ui/Spinner';
import { TrackJS } from "trackjs";
import queryString from 'query-string';
import moment from 'moment';
import {loadStripe} from '@stripe/stripe-js';
import { Context } from '../../../common/Context';
import { useLink } from 'valuelink';
import Methods from './Methods';
import PayGoogle from './PayGoogle';
import Subscription from './Subscription';

//TODO: handle error with a popup; use react-query
//TODO: use Stripe react hook: https://github.com/stripe-samples/checkout-one-time-payments/blob/master/client/react-cra/src/components/Checkout.js

const Pay = (props) => {

	const PRICE = 0.8;

	const [toBuy, setToBuy] = React.useState(5);
	const [sending, setSending] = React.useState(false);
	const [remains, setRemains] = React.useState();
	const [error, setError] = React.useState(null);
	// const campaign, setCampaign] = React.useState(null);
	const [eligible, setEligible] = React.useState(true);
	const [registrationDate, setRegistrationDate] = React.useState(null);
	const {state : {serverUser: user, serverAuthenticated}, dispatch} = React.useContext(Context);

	const { t } = useTranslation();

	const testKey = "pk_test_Sq3jxn8fBH2IylMGthvhl1Vu00S8oBNLzs";
	const liveKey = "pk_live_m6SL7BPKX62OY1DqlZatlL1w00iRaYc1cf";
	const isProduction = (process.env.NODE_ENV === 'production');
	const stripeKey = isProduction? liveKey : testKey;

	React.useEffect(() => { serverAuthenticated && setTotal(); }, [serverAuthenticated]);

	const campaign = getCampaign();
	React.useEffect(() => { serverAuthenticated && setEligibility(campaign); }, [serverAuthenticated]);

	const methodLink = useLink();

	const { isSuccess: subscriptionStatusLoaded, data: subscriptionStatus } = useQuery(['subscription', serverAuthenticated], loadSubscriptionStatus);

	React.useEffect(() => { serverAuthenticated && resetShouldPay(); }, [serverAuthenticated]);	//reset the shouldPay flag so that the dialog doesn't obstruct the view

	//for mobile app, redirect to another component
	// const isTWA = ('getDigitalGoodsService' in window);	
	// if (isTWA) {
	// 	return (<PayGoogle/>);
	// }

	//before we updated the number of remaining texts, don't show anything
	if (remains === undefined || !user || !subscriptionStatusLoaded) {
		return (<Spinner/>);
	}

	//while waiting, show spinner
	if (sending) {
		return (<Spinner/>);
	}

	//if subscription is still valid, redirect
	if (subscriptionStatus?.isValid) {
		return <Subscription {...subscriptionStatus} />
	}


	return (
		<main className="pay">
			<div className="pay-left">
				<em>{ReactHtmlParser(t('pay-left', {count: remains}))}</em>
				{ReactHtmlParser(t('pay-continue'))}
			</div>

			<div className="pay-click pay-button pay-subscribe" onClick={subscribe}>{t('pay-subscribe')}</div>
			<div className="pay-subscribe2 pay-subscribe">{t('pay-subscribe2')}</div>

			<div className="pay-or">{t('pay-or')}</div>

			<div className="pay-total">
				<span style={{fontWeight: 400}} >&euro;</span>{campaign.total(toBuy).toFixed(2)}
			</div>

			<div className="pay-buy">
				<div className="pay-click square pay-decrease" onClick={() => setToBuy(approve(toBuy - 1))}>-</div>
				<div className="pay-value square">{toBuy}</div>
				<div className="pay-click square pay-increase" onClick={() => setToBuy(approve(toBuy + 1))}>+</div>
			</div>
			
			<div className={`pay-click pay-button ${!eligible && 'disabled'}`} onClick={async () => eligible && (await handlePayment())}>
				{t('pay-buy')}
			</div>

			{eligible && (
				<>
				<div className="pay-campaign-part1">
					{t(`pay-${campaign.name}-part1`)}
				</div>
				<div className="pay-campaign-part2">
					{t(`pay-${campaign.name}-part2`)}
				</div>
				</>
			)}

			{user.paying && <div className="pay-methods">
				<Methods methodLink={methodLink} />
			</div>}
			
			{ !eligible && (<div className="pay-error">{ReactHtmlParser(t(`pay-ineligible`))}</div>)}

			{ error && (<div className="pay-error">{error}</div>)}

			<div className="pay-after">
				{ReactHtmlParser(t('pay-after'))}
			</div>

		</main>
	)

	function approve(newToBuy){
		return campaign.approve(newToBuy)? newToBuy : toBuy;
	}

	async function handlePayment() {
		setSending(true);

		if (user.paying && (methodLink.value !== 'new')) {
			//User has paid at least once, didn't choose "new card", use the saved card data workflow
			await payNext();
		} else {
			//User has never paid, redirect them to the hosted Stripe form
			await redirectToPaymentForm();
		}
		setSending(false);	//hide the spinner in case there was an error
	}

	async function redirectToPaymentForm(){
		let sessionId = await getSessionId();
		if(!sessionId) return;
		await redirectToCheckout(sessionId);
	}

	async function redirectToCheckout(sessionId){
		let stripe = await loadStripe(stripeKey);
		let redirect = stripe.redirectToCheckout({
			sessionId
		});

		redirect.then((result) => {
			// If `redirectToCheckout` fails due to a browser or network
			// error, display the localized error message to your customer
			// using `result.error.message`.
			if (result.error) {
				let message = result.error.message;
				TrackJS.track(result.error);
				setError(message);
			}
		});

	}

	async function getSessionId(){
		let message = "flyent, " + t('my-payments-text-count', { count: toBuy });
		try {
			let sessionId = (await axios.post(`/UserManagement/Payments/first`, { toBuy, message, isProduction, campaignName: campaign.name })).data;			
			return sessionId;
		} catch (error) {
			const message = error.response.data;
			setError(message);	//TODO: set generic error message
			console.error(message);
		}		
	}

	async function subscribe(){
		let sessionId = await getSubscriptionSessionId();
		await redirectToCheckout(sessionId);
	}

	//subscriptions
	async function getSubscriptionSessionId(){
		try {
			let sessionId = (await axios.post(`/UserManagement/Payments/subscribe`, {  })).data;			
			return sessionId;
		} catch (error) {
			const message = error.response.data;
			setError(message);	//TODO: set generic error message
			console.error(message);
		}			
	}

	async function payNext(){				
		let message = "flyent, " + t('my-payments-text-count', { count: toBuy });
		let intent = await getIntent(toBuy, message);
		if(!intent) return;	//must have been an error

		if (intent.status === 'requires_action') {
			let stripe = await loadStripe(stripeKey);
			const {paymentIntent, error} = await stripe.confirmCardPayment(intent.clientSecret);

			if (!error) {
				intent = paymentIntent;	//updated payment status
			} else {
				TrackJS.track(error);
				alert(error.message);
				setError(error.message);
			}
		}

		if (intent.status === 'succeeded') {
			user.paying = true;		//not sure we need this
			props.history.push(`/Payments/Success?intent=${intent.id}`);
		} else {
			TrackJS.track(intent);
			alert("Error processing payment, trying again");
			await redirectToPaymentForm();
		}
	}

	async function getIntent(toBuy, message) {
		setSending(true);

		try {
			//TODO: use React-query
			let paymentResponse = await axios.post('/UserManagement/Payments/followup', { toBuy, message, campaignName: campaign.name, paymentMethodID: methodLink.value, isProduction });
			return paymentResponse.data;
		} catch (error) {
			TrackJS.track(error);
			let errorData = error.response.data;
			setError(errorData);
		} finally {
			setSending(false);
		}
	}

	//TODO: react-query
	async function setTotal() {
		let response = await axios.get('/UserManagement/Credits/total');
		if (response) {			//if authenticated
			let credits = parseInt(response.data);
			setRemains(credits);
		}
	}

	/////// CAMPAIGN //////////
	function getCampaign(){

		const defaultCampaign = {
			name: "5for4",
			approve: newToBuy => (newToBuy > 0),
			total: (toBuy) => (toBuy - (toBuy/5|0)) * PRICE,
			approveEligibility: async () => true
		}

		const halfPriceCampaign = {
			name: "HalfPrice",
			approve: newToBuy => (newToBuy >= 10),
			total: (toBuy) => toBuy * PRICE / 2,
			approveEligibility: async () => {
				let registrationDate = (await axios.get('/UserManagement/Login/registration')).data;
				setRegistrationDate(registrationDate);
				let now = moment();
				let regDaysAgo = now.diff(moment(registrationDate), 'days');
				return regDaysAgo <= 2;
			}
		}
		
		const params = queryString.parse(window.location.search);
		const campaignName = params.campaign || params.utm_campaign;
		if (campaignName === "HalfPrice") {
			if (!halfPriceCampaign.approve(toBuy)) {
				setToBuy(10);	//initialize
			}
			return halfPriceCampaign;
		}
		return defaultCampaign;
	}

	async function setEligibility(campaign){
		setEligible(await campaign.approveEligibility());
	}

	async function loadSubscriptionStatus(){
		const url = '/UserManagement/Payments/subscription_status';
		const subscriptionStatus = (await axios.get(url)).data;
		return subscriptionStatus;
	}

	function resetShouldPay(){
		dispatch({type: 'SHOULD_PAY', payload: {shouldPay: false}});
	}

}


export default Pay;
