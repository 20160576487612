import React from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useQuery } from 'react-query';
import Spinner from '../../../common/ui/Spinner';
import queryString from 'query-string';

const Success = (props) => {

	const { t } = useTranslation();

	const { isLoading, data:remains } = useQuery('credits',  () => {
		return getRemainingCredits();
	});

	if (isLoading) {
		return (<Spinner/>);
	}


	return (
		<React.Fragment>
			<h2 className="pay-thanks">
				{t('pay-success-thanks')}
			</h2>

			<div className="pay-left">
				<em>{ReactHtmlParser(t('pay-left', {count: remains}))}</em>
				{ReactHtmlParser(t('pay-continue'))}
			</div>

			<Link className="pay-back-button" to="/Students/Start">
				{t('pay-success-back')}
			</Link>
		</React.Fragment>
	)

	async function getRemainingCredits(){
		let intentId = getIntentId();
		if (intentId) {
			let creditsUpdated = false;
			while (!creditsUpdated) {
				creditsUpdated = (await axios.get(`/UserManagement/Payments/${intentId}`)).data;
			}
		}
		let response = await axios.get('/UserManagement/Credits/total');
		return response? parseInt(response.data) : null;
	}

	function getIntentId(){
		let params = queryString.parse(window.location.search);
		return params.intent;
	}
}

export default Success;
