import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {Context} from '../../../common/Context';
import Bubbles from './Bubbles';

const statsText = "times";  //TODO: localization

const Stats = (props) => {

	// console.log("Stats rerender");

	const [usageCount, setUsageCount] = React.useState();
	const [usageInSeconds, setUsageInSeconds] = React.useState(0);
	const pendingTimeRef = React.useRef(0);
	const { isAuthenticated, user } = useAuth0();
	const anonIdRef = React.useRef();
	const {state, dispatch} = React.useContext(Context);
	const serverAuthenticated = !!state.serverAuthenticated;

	React.useEffect(() => {
		if (isAuthenticated && serverAuthenticated || (pendingTimeRef.current > 0)) {
			sendStats();
		}
	}, [isAuthenticated, serverAuthenticated,  props.playerState, state.phraseIndex]);//we should send stats when the player state changes (start/stop), or we move to the next phrase (useful for payment popups)

	const prevTime = usePrevious(state.playedPosition);
	React.useEffect(() => {
		if (props.playerState === 'playing') {
			pendingTimeRef.current += (state.playedPosition - prevTime);
			if(state.playedPosition < prevTime)
				pendingTimeRef.current += props.duration;	//to avoid negative times
		}
	}, [state.playedPosition]);

	//on unmount, ask for push subscription
	React.useEffect(() => {
		return () => {
			dispatch({ type: 'CLOSE_PLAYER', payload: {usageCount} });
		}
	}, []);

	//an utility function to use instead of prevprops
	function usePrevious(value) {
		const ref = React.useRef();
		React.useEffect(() => {
		  	ref.current = value;
		});
		return ref.current;
	}

    function resetPlayedTime() {
        pendingTimeRef.current = 0;
    }

    async function sendStats(){
		let url = "/Students/Stats/PlayerStats";
		let anonId = getAnonId();
        let data = {
            itemName: props.itemName,
            lang: props.lang,
            usageInSeconds: Math.round(pendingTimeRef.current),
			duration: props.duration,
			anonName: anonId,
			authenticated: isAuthenticated 	//so that the server knows we think we're authenticated and relogins us if needed
        };

        resetPlayedTime(); //we need to do it before sending so that we don't send it again while we're waiting

        try {
            let response = await axios.post(url, data);//.then(r => r.json());
            if (response) {
				let data = response.data;
				console.log(user);

				dispatch({type: 'SHOULD_PAY', payload: {shouldPay: !!data.shouldPay}});
				if (data.shouldPay) {	//we are trying to listen without paying
					window.analytics?.track("Should Pay", {user: user.nickname});
				} else {
					//this.setState(data);
					setUsageCount(data.usageCount);
					setUsageInSeconds(data.usageInSeconds);
					// this.trackPlayed(response.data.usageCount);
					dispatch({ type: 'STATS', payload: { playerStats: data } });		//notify other components
				}
				
            } else {
                //there was an error sending request, but it's been handled already
                console.error("Error sending stats");
                pendingTimeRef.current = data.usageInSeconds;
            }

        } catch (error) {
            console.error(error)
            pendingTimeRef.current = data.usageInSeconds;
            // throw;   //don't throw, cause we might be offline
        }
    }

	const percentage = usageCount;
	const visibility = isAuthenticated? 'visible' : 'hidden';
	return (
		<div id="statsWrapper" style={{height: '74px', width: '74px', visibility}}>
			<svg id="statsFrame">
				<circle cx="37" cy="37" r="37" strokeWidth="1" fill="none" />
				<circle cx="37" cy="37" r="25" strokeWidth="1" fill="none" />
			</svg>

			<div id="statsText" title={`${usageCount} ${statsText}`}>
				<div style={{
					fontSize: '24px',
					lineHeight: '100%'
				}}>{usageCount}</div>
			</div>

			<CircularProgressbar
				percentage={percentage}
				strokeWidth={16}
				styles={{
					path: {
						strokeLinecap: 'butt',
					},
					trail: { stroke: 'transparent' }
				}}
			/>

			{(usageCount !== undefined) && 
				<Bubbles numBubbles={usageCount} />}

		</div>
	)

	function getAnonId() {
		let anonId = anonIdRef.current;
		if (anonId) {
			return anonId;
		}

		anonId = window.analytics && window.analytics.user && window.analytics.user().anonymousId();
		if (!anonId) {	//e.g. when Segment is blocked by an adblocker
			anonId = new Date().toString();
		}
		anonIdRef.current = anonId;
		
		return anonId;
	}

}

export default withRouter(Stats);
