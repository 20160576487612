import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

function References(){
	const { t } = useTranslation();
	if (!t('home-reference')) {
		return null;
	}
	return (
		<React.Fragment>
			<div className="home-header">{t('home-reference-header')}</div>
			
			<div className="reference">
				{ReactHtmlParser(t('home-reference'))}
			</div>
		</React.Fragment>
	)	
}

export default (References);
