import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import SmileAndStart from './SmileAndStart';
import eyeOpen from './eye-open.svg';
import eyeSleep from './eye-sleep.svg';

class TwoSteps extends Component {
    render() {
        return (
            <React.Fragment>

				<div className="two-steps row">
					<div className="home-header">
						{this.props.t('two-steps-header')}
					</div>

					<div className="two-steps-item col-sm-6">

						<img src={eyeOpen} className="two-steps-image"/>

						<div className="two-steps-description">
							<h2>
								{ReactHtmlParser(this.props.t('two-steps-active-header'))}
							</h2>
							{ReactHtmlParser(this.props.t('two-steps-active-text'))}
						</div>
					</div>

					<div className="two-steps-item passive col-sm-6">

						<img src={eyeSleep} className="two-steps-image passive"/>

						<div className="two-steps-description">
							<h2>
								{ReactHtmlParser(this.props.t('two-steps-passive-header'))}
							</h2>
							{ReactHtmlParser(this.props.t('two-steps-passive-text'))}
						</div>
					</div>

					<SmileAndStart preview={this.props.preview} setShowEmailDialog={this.props.setShowEmailDialog}/>
				</div>

            </React.Fragment>
        )
    }
}

export default withTranslation()(TwoSteps);
