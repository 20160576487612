import React from 'react';
import useQueryAuthenticated from '../../../common/use_authenticated_query';
import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { Context } from '../../../common/Context';
import Toastify from 'toastify-js';
import { useTranslation } from 'react-i18next';



const Bookmark = (props) => {
	const { state } = React.useContext(Context);
	const lang = state.langData.knownLang;
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { isLoading, isSuccess, data: isSaved } = useQueryAuthenticated(['bookmark', props.phraseId], checkBookmark);
	const mutation = useMutation(toggleBookmark, {
		onMutate: () => queryClient.setQueryData(['bookmark', props.phraseId, true], old => !old),
		onSettled: () => queryClient.invalidateQueries(['bookmark', props.phraseId, true])
	});

	if (!isSuccess) {
		return null;
	}

	
	return (
		<React.Fragment>
			<div className={`btn bookmark`}  onClick={() => mutation.mutate()} id="bookmark">
				<i className="icon-bookmark-empty" style={{opacity: isSaved? '1' : '0.5'}}></i>
			</div>			
		</React.Fragment>
	);

	async function checkBookmark(){
		var isSaved = (await axios.get(`/Students/card/${props.phraseId}/${lang}`)).data;
		return isSaved;
	}

	async function toggleBookmark() {
		var newValue = !isSaved;
		await axios.post(`/Students/card/${props.phraseId}/${lang}`, {isSaved: newValue});
		const cache = await caches.open("workbox-api");
		await cache.delete(`/Students/card/${props.phraseId}/${lang}`, {ignoreVary: true});
		const message = newValue? t("player-bookmark-added") : t("player-bookmark-removed");
		notify(message);	
	}

	function notify(message) {

		Toastify({
			text: message,
			selector: 'bookmark',
			duration: 1000,
			close: false,
			gravity: "bottom", // `top` or `bottom`
			position: "center", // `left`, `center` or `right`
			className: "toastify-flyent",
			stopOnFocus: true, // Prevents dismissing of toast on hover
			offset: { x: 0, y: 30 },
		  }).showToast();		
	}

}

export default Bookmark;
