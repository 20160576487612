import React, { Component } from 'react';
import GridLoader from '@bit/davidhu2000.react-spinners.grid-loader';


class Spinner extends Component {
	state = { };

	render() {
		let size = this.props.size || 15;
		let css = this.props.css?? "margin: 2rem auto";
		return (
			<div style={{width: '100%', textAlign: 'center'}}>
					<GridLoader className="loader"
						color='#86ac9f'
						css={css}
						size={size}
						/>
			</div>
		)
	}
}

export default Spinner;
