import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

class Difference extends Component {
    render() {
        return (
          <React.Fragment>
            <div className="home-header">
              {this.props.t("difference-header")}
            </div>

            <table className="difference">
				<tbody>
					<tr>
						<td />
						<td>{this.props.t("difference-time")}</td>
						<td>{this.props.t("difference-remember")}</td>
						<td>{this.props.t("difference-texts")}</td>
					</tr>

					<tr>
						<td className="first flyent">flyent</td>
						<td className="plus"><Plus/></td>
						<td className="plus"><Plus/></td>
						<td className="plus"><Plus/></td>
					</tr>

					<tr>
						<td className="first">
							{this.props.t("difference-apps")}
						</td>
						<td className="minus"><Minus/></td>
						<td className="minus"><Minus/></td>
						<td className="plus"><Plus/></td>
					</tr>

					<tr>
						<td className="first">
							{this.props.t("difference-ebooks")}
						</td>
						<td className="plus"><Plus/></td>
						<td className="plus"><Plus/></td>
						<td className="minus"><Minus/></td>
					</tr>

					<tr>
						<td className="first">
							{this.props.t("difference-courses")}
						</td>
						<td className="minus"><Minus/></td>
						<td className="minus"><Minus/></td>
						<td className="plus"><Plus/></td>
					</tr>
				</tbody>
            </table>
          </React.Fragment>
        );
    }
}

export default withTranslation()(Difference);

function Plus() {
	return (<FontAwesomeIcon icon={faCheck} />)
}

function Minus() {
	return (<FontAwesomeIcon icon={faTimes} />)
}
