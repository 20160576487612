import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {withRouter} from 'react-router';

class TopHeader extends Component {
    render() {
        let model = this.props.model;
        let moduleName = (model.translation && model.translation.moduleName) || model.moduleName || model.details.moduleName || model.localizedLevel;
        let title = model.title || model.name;
        return (
            <React.Fragment>
                <div style={{display: 'inline-block', width: '100%'}}>
                    <div id="moduleNameTop" className="shorten">
                        <a
                            onClick={() => this.props.history.push(`/Students/Start#${moduleName}`)}
                            title={moduleName}>
                            {moduleName}
                        </a>
                    </div>

                    <div id="title" className="shorten" title={title}>
                        {title}
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

export default (TopHeader);
