import React from 'react';
import { useTranslation } from 'react-i18next';
//import { Context } from '../../../common/Context';
import { useAuth0 } from '@auth0/auth0-react';
import DialogWrapper from '../../../common/DialogWrapper';
import ReactHtmlParser from 'react-html-parser';
import BonusLink from '../../users/history/BonusLink';
import { Context } from '../../../common/Context';
import axios from 'axios';

const STORAGE_KEY = "flyent_please-refer_shown";

// popup encouraging users to refer -- opens up on leaving the Player
const PleaseReferPopup = () => {
	const [display, setDisplay] = React.useState(false);
	
	const { state } = React.useContext(Context);

	React.useEffect(() => { state.serverAuthenticated && showOrHide(); }, [state.serverAuthenticated]);

	if (!state.serverAuthenticated) {
		return null;
	}
	
	return (
		<DialogWrapper
			title="please-refer-title" 
			show={display}
			cancel="DialogClose"
			onCancel={() => setDisplay(false)}
			className="pleaseReferPopup"
		>
			<PleaseRefer/>
		</DialogWrapper>
	)

	async function showOrHide(){
		const show = await shouldShow();
		if (show) {
			window.localStorage.setItem(STORAGE_KEY, true);
		}
		setDisplay(show);
	}

	async function shouldShow(){
		const alreadyShown = !!window.localStorage.getItem(STORAGE_KEY);
		if (alreadyShown) {
			return false;
		}

		//check the # of remaining credits
		let response = await axios.get('/UserManagement/Credits/total');
		let credits = parseInt(response.data);
		
		return (credits <= 2);
	}
}

function PleaseRefer(){
	const { t } = useTranslation();
	const { state } = React.useContext(Context);

	const reviewLink = "https://play.google.com/store/apps/details?id=online.flyent.twa";
	const supportLink = "https://flyent.online/User/Support";
	const cta1 = `<a href='${reviewLink}' target='_blank'>${t("please-refer-option2-cta1")}</a>`;
	const cta2 = `<a href='${supportLink}' target='_blank'>${t("please-refer-option2-cta2")}</a>`;

	if (!state.serverAuthenticated) {
		return null;
	}
	
	return (
	<div style={{fontSize: '1rem'}}>
		<div>
			{t("please-refer-description")}
		</div>

		<div style={{marginTop: '1rem', marginBottom: '0.5rem'}}>
			{t("please-refer-option1")}
		</div>
		 
		<BonusLink referralCode={state.serverUser.referralCode} />

		<div style={{marginTop: '1rem'}}>
			{ReactHtmlParser(t("please-refer-option2", {cta1, cta2, interpolation: { escapeValue: false } }))}
		</div>

	</div>
)}



export default PleaseReferPopup;
