
import i18next from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { useTranslation, initReactI18next } from "react-i18next";
import XHR from 'i18next-xhr-backend';
import deepEqual from 'deep-equal';
import queryString from 'query-string';
import store from './store';

export default async function loadLanguages(dispatch) {
    const detectionOptions = {
        lookupQuerystring: 'knownLang'
    };
    const lngDetector = new LngDetector(null, detectionOptions);
    await i18next
		.use(lngDetector)
		.use(initReactI18next)
		.use(XHR)
		.init({
			detection: {
				lookupQuerystring: 'knownLang'
			},
			fallbackLng: 'en',
			react: {
				wait: true,
				useSuspense: false
			},
		});
	let languages = getSupportedlanguages();
	//1. list of options
	//1a. ru/lt and en/lt
	//1b. localStorage.langData
	//1c. from query string
	let availablePairs = [
		{
			learnedLang: "lt",
			knownLang: "en",
			translatedOnly: true
		},
		{
			learnedLang: "lt",
			knownLang: "ua",
			translatedOnly: true
		},
		{
			learnedLang: "lt",
			knownLang: "ru",
			translatedOnly: true
		},
	];
	let previousLangData = JSON.parse(localStorage.getItem('langData'));
	addLangPair(previousLangData);
	let queryData = queryString.parse(window.location.search);
	addLangPair(queryData);
	dispatch({ type: 'LANGUAGE_PAIRS', payload: { languagePairs: availablePairs } });
	// this.setState({availablePairs});
	//add a pair if it's not there already
	function addLangPair(pair) {
		//don't add if no relevant data
		if (!isValidLangPair(pair)) {
			return;
		}

		//don't add if already added
		pair = cleanLangData(pair);
		let found = availablePairs.find(langData => JSON.stringify(pair) === JSON.stringify(langData));//deepEqual(pair, langData)
		if (!found) {
			availablePairs.push(pair);
		}
	}
	//2. selected option
	//2a. query string
	//2b. localStorage.langData
	//2c. match available options with localization
	//2d. en (TODO: add en as fallback for 2c.)
	let selectedLangPair = {};
	selectLangPair(queryData); //data from query string is the 1st priority
	selectLangPair(previousLangData); //last used parameters are the 2nd
	//now, let's check a match between available translations and user's languages
	let availableKnownLangs = availablePairs.map(pair => pair.knownLang);
	let knownLang = languages.find(lang => availableKnownLangs.includes(lang));
	if (knownLang) {
		selectLangPair(availablePairs.find(pair => pair.knownLang === knownLang));
	}
	//tell the others
	store.dispatch({ type: 'SELECTED_LANG', payload: { langData: selectedLangPair } });
	dispatch({ type: 'SELECTED_LANG', payload: { langData: selectedLangPair } });
	//save to local storage
	if (isValidLangPair(selectedLangPair)) {
		localStorage.setItem('langData', JSON.stringify(selectedLangPair));
	}
	let selectedLang = selectedLangPair.knownLang;
	if (selectedLang !== i18next.language)
		await i18next.changeLanguage(selectedLang);
	function selectLangPair(pair) {
		//if selectedLangPair is not set yet and pair is valid, set it
		if (!isValidLangPair(selectedLangPair) && isValidLangPair(pair)) {
			selectedLangPair = cleanLangData(pair);
		}
	}
	function isValidLangPair(pair) {
		if (!pair)
			return false;
		return !!(pair.learnedLang && pair.knownLang);
	}
	function cleanLangData(langData) {
		let { learnedLang, knownLang, translatedOnly } = langData;
		if (translatedOnly === undefined) {
			translatedOnly = true; //only translated by default
		}
		return { learnedLang, knownLang, translatedOnly };
	}

    //all languages that the user can understand: supported by e.g. user browser
    function getSupportedlanguages() {
        let detectionOrder = lngDetector.options.order;

        let detected = [];
        detectionOrder.forEach(detectorName => {
          if (lngDetector.detectors[detectorName]) {
            let lookup = lngDetector.detectors[detectorName].lookup(lngDetector.options);
            if (lookup && typeof lookup === 'string') lookup = [lookup];
            if (lookup) detected = detected.concat(lookup);
          }
        });

        return detected;
    }


}