import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import Routes from '../routes';
import ErrorBoundary from './ErrorBoundary';
import LangSwitcher from './top/LangSwitcher';
import HelpButton from '../features/students/help/HelpButton';
import './ui/Styles.scss';
import Avatar from '../features/users/Avatar';
import AccountMenuDialog from '../features/users/AccountMenuDialog';
import back from './ui/images/back.svg';
import RedDot from '../features/users/RedDot';
import logo from './ui/images/fl_log.svg'
// import bg from './1_razdeli.png';

export default class MainLayout extends Component {
    render() {
		const url = this.props.location.pathname;
		const className = this.url2class(url);		//add class name so that we can apply different CSS rules depending on the URL
		return (
			<div style={{height: '100%'}} className={`mainLayout ${className}`}>
				<div className="navbar navbar-default navbar-static-top" itemScope itemType="http://schema.org/WPHeader">
					<nav className="container" itemScope itemType="http://schema.org/SiteNavigationElement">
						<div className="navbar-brand">
							<Switch>
								<Route path="/Students/Start" render={() => 
									<Link to="/#stay"><img src={logo} id="logo" alt="flyent logo"/></Link>
								}/>
								<Route render={() => 
									<Link to="/Students/Start"><img src={back} id="back" alt="back to list"/></Link>
								}/>
							</Switch>

						</div>

						<div id="topMenu">
							<div id="langSwitcherWrapper"><LangSwitcher/></div>

							<Route path="/Students/Player" render={() => 
								<div id="moduleAndTitle"></div>
							}/>
							

							<Route path="/Students/Start" render={() => 
								<div id="helpWrapper"><HelpButton/></div>
							}/>

						</div>

						<div className="avatarAndRedDotWrapper">
							<div className="avatarWrapper">
								<Avatar/>
							</div>							
							<RedDot/>
						</div>


					</nav>
				</div>

				<div id="firstStrip"></div>

				<div className="container-fluid" id="mainContainer">
					<ErrorBoundary>
						<Routes/>
					</ErrorBoundary>
				</div>
				
				<AccountMenuDialog/>

				{/* <div id="attribution">Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> */}

			</div>
		)
    }

	url2class(url) {
		return url.slice(1).replace(/\//g, ' ').toLowerCase();
	}
  }
