import React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import DialogWrapper from './DialogWrapper';
import { Context } from './Context';
import { useAuth0 } from '@auth0/auth0-react';
import { Workbox } from 'workbox-window';


const AutoUpdate = () => {
	const [display, setDisplay] = React.useState(false);
	const { isAuthenticated } = useAuth0();
	const {state, dispatch} = React.useContext(Context);
	const { t } = useTranslation();

	React.useEffect(() => {
		window.setInterval(() => checkForUpdates(), 3600000);
	}, []);

	//new behavior -- reload whenever a new service worked is found
	React.useEffect(() => {
		if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
			const wb = new Workbox('/custom-service-worker.js');
		   
			wb.addEventListener('installed', event => {
				if (event.isUpdate) {
					setDisplay(true);
				}
			});
		  
			wb.register();
		  }		
	}, []);

	

	async function checkForUpdates() {
		let latestVersion = (await axios.get('/datetime.txt')).data.trim();
		if (!latestVersion) {
			return;
		}

		//only fo authenticated users
		if (!isAuthenticated) {
			return;
		}

		//if empty, just set and quit
		let currentVersion = state.version;
		if (!currentVersion) {
			setVersion(latestVersion);
			console.log('quitting AutoUpdate');
			return;
		}

		//if not, compare
		let display = (currentVersion !== latestVersion);
		setDisplay(display);
	}
	
	return (
		<React.Fragment>
			<DialogWrapper 
				title="AutoUpdate-title" 
				show={display}
				cta="AutoUpdate-update"
				onOK={() => reload()}
				cancel="AutoUpdate-cancel"
				onCancel={() => setDisplay(false)}
			>
				{t('AutoUpdate-message')}
			</DialogWrapper>
		</React.Fragment>
	)


	function reload() {
		window.location.reload();
	}

	function setVersion(version){
		dispatch({
			type: 'VERSION',
			payload: {version}
		})
	}
}



export default AutoUpdate;
