import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import libo from '../../home/libosiara.svg';


class SmileAndBuy extends Component {
	state = { };

	render() {
		return (
			<div className="smile-and-buy">
				<img className="libo" src={libo} />
				<span className="yellowButton" onClick={() => {this.props.history.push('/Payments/Pay')}}>
					<span className="buy">{this.props.t('my-texts-buy')}</span> <span className="more">{this.props.t('my-texts-more')}</span>
				</span>
			</div>
		)
	}
}

export default withRouter(withTranslation()(SmileAndBuy));
