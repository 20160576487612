import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import Item from './Item';

class Module extends Component {

	state = { displayTop3: (this.props.items.length > 3) };

	render() {
		return this.renderGroup(this.props.moduleName, this.props.items)
	}

	renderGroup(moduleName, items) {
		let panelClass = items.type || 'common';
		// let isExpanded = this.state.selectedGroup === moduleName;
		let expandedClass = this.props.isExpanded ? 'expanded' : 'collapsed';
		return ( 
			<Panel 
				bsStyle = "success"
				key = {moduleName}
				eventKey = {moduleName}
				className = {'module ' + panelClass + ' ' + expandedClass}
			>

				<Panel.Heading>
					<Panel.Toggle className = 'moduleHeader'>
						<Panel.Title componentClass = "h4" className = "title" > 
							{moduleName}
						</Panel.Title> 
					</Panel.Toggle> 
				</Panel.Heading>

				<Panel.Body collapsible className = 'moduleBody' > {
					items.map((item, index) => {
						let shouldDisplayItem = index < 3 || !this.state.displayTop3;
						if (shouldDisplayItem) {
							return ( 
								< Item 
									item = {item}
									key = {index}
								/>
							)
						} else {
							return null;
						}
					} )} 

					{this.state.displayTop3 && 
						<div onClick={() => this.setState({displayTop3: false})} style={{cursor: 'pointer'}} className="moduleShowAll">
							{this.props.t('list-show-all')}
						</div>
					}
				</Panel.Body>

				<hr className = "moduleDivider" / >
			</Panel>
		)
	}

}

export default withTranslation()(Module);