import React from 'react';
import { useTranslation } from 'react-i18next';
//import { Context } from '../../../common/Context';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const DIGITAL_GOODS_API_KEY = "Amdpc4oIHtsL5L/BrpSjxcCw8Tq9NB3omYfmmCiPEmDi1CGTJ+v52Dhye8EUfLtOL9tu4/TcF12+3ajbOV5LIAMAAABTeyJvcmlnaW4iOiJodHRwczovL2ZseWVudC5vbmxpbmU6NDQzIiwiZmVhdHVyZSI6IkRpZ2l0YWxHb29kcyIsImV4cGlyeSI6MTYzMTY2Mzk5OX0=";
const DIGITAL_GOODS_API_KEY_V2 = "AouNv+LqVWqq+EwHR91df7lwwz+e/PGu0WDleMWLFGPd7BdBnh7a+pAhGQqmMaYM5Ey5AWVVVqn/+QS3B7Ws1AsAAABVeyJvcmlnaW4iOiJodHRwczovL2ZseWVudC5vbmxpbmU6NDQzIiwiZmVhdHVyZSI6IkRpZ2l0YWxHb29kc1YyIiwiZXhwaXJ5IjoxNjUzMjYzOTk5fQ==";


const PayGoogle = (props) => {
	//const { state, dispatch } = React.useContext(Context);
	const { user, isAuthenticated, isLoading } = useAuth0();
	const { t } = useTranslation();
	const [error, setError] = React.useState();

	if (!isAuthenticated) {
		return null;
	}

	return <main className="pay">
		<div className="pay-left">
			<em>Sorry, payments on mobile apps are not implemented yet. You can&nbsp;  
				<a href="https://flyent.online/Payments/Pay" target="_blank">pay on our Web version</a>
			</em>			
		</div>

		<button className="btn btn-success btn-lg" style={{display: 'block', margin: 'auto'}} onClick={makePurchase}>FOR TESTERS</button>

		<div className="pay-error">{error}</div>
	</main>
	

	async function makePurchase() {
		if (!window.getDigitalGoodsService) {
			setError("Digital Goods API is not supported");
			return;
		}

		// Digital Goods API is supported!
		let service = null;
		try {
			service =
				await window.getDigitalGoodsService('https://play.google.com/billing');
			if (!service) {	//v1
				setError("Play Billing is not available");
				return;				
			}
			
		} catch (error) {		//v2
			setError("Play Billing is not available");
			return;
		}
		
		

		if (service) {
			// Google Play Billing is supported!
			// Define the preferred payment method and item ID
			const paymentMethods = [{
				supportedMethods: "https://play.google.com/billing",
				data: {
					sku: '1_subscription',
				}
			}];
	
			const paymentDetails = {
				total: {
					label: 'Total',
					amount: {currency: 'USD', value: '0'}
				}
			};

			try {
				
				const request = new PaymentRequest(paymentMethods, paymentDetails);
				const paymentResponse = await request.show();
				const {token} = paymentResponse.details;

				// Call backend to validate the purchase.
				if (await validatePurchaseOnBackend(token)) {
					// Acknowledge using the Digital Goods API. Use ‘onetime' for items
					// that can only be purchased once and ‘repeatable for items
					// that can be purchased multiple times.
					await service.acknowledge(token, 'repeatable');
			
					// Optional: tell the PaymentRequest API the validation was
					// successful. The user-agent may show a "payment successful"
					// message to the user.
					const paymentComplete = await paymentResponse.complete('success');
					debugger;

					//redirect to the success page
					props.history.push(`/Payments/Success`);
				} else {
					debugger;
					// Optional: tell the PaymentRequest API the validation failed. The
					// user agent may show a message to the user.
					const paymentComplete = await paymentResponse.complete('fail');
					//display error message
				}				
			} catch (error) {
				console.error(error);
				if (error.code === DOMException.ABORT_ERR) {
					//payment canceled -- show error message
					setError("Payment canceled");
				} else {
					//display error.message
					setError(error.message);
				}
				debugger;
			}
		}		

	}

	async function validatePurchaseOnBackend(token){
		await axios.post('/UserManagement/Payments/billing_subscribe');
		return true;
	}
}

export default PayGoogle;
