import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import vera from './vera.webp';

class Vera extends Component {
    render() {
        return (
            <React.Fragment>
				<div className="home-header">
					{this.props.t('science-header')}
				</div>

				<div className="vera-wrapper">
					<img src={vera} alt="Vera Felicitas Birkenbihl" loading="lazy"/>
				</div>

				<div className="science science-description">
					{ReactHtmlParser(this.props.t('science-text'))}
				</div>

            </React.Fragment>
        )
    }
}

export default withTranslation()(Vera);
