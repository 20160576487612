import React from 'react';
import { withTranslation } from 'react-i18next';
import DialogWrapper from '../../common/DialogWrapper';
import axios from 'axios';
import { Context } from '../../common/Context';
import { useAuth0 } from '@auth0/auth0-react';

//push notifications
const AskForPush = (props) => {
	// console.log("AskForPush rerender");

	const STORAGE_NAME = "askedForPush";

	const [answer, setAnswer] = React.useState();

	React.useEffect(() => { askNative(answer); }, [answer]);

	const {state} = React.useContext(Context);

	const { isAuthenticated } = useAuth0();

	//if answer === true => request permission using a hook;save to localStorage 
	//if answer === false => just save to localStorage
	//if answer is undefined, show the dialog

	if (!shouldShow()) {
		return null;
	}

	//if just got an answer, save it so that we don't ask again
	if (answer !== undefined) {
		saveAlreadyAsked();
		return null;
	}

	//if no answer, we have to get it
	return (
		<DialogWrapper 
			title="push-title" 
			show={true}
			cta="yes"
			onOK={() => {setAnswer(true);}}
			cancel="no"
			onCancel={() => {setAnswer(false);}}>
			{props.t('push-text')}
		</DialogWrapper>
	)

	function shouldShow() {

		//ignore non-authenticated users
		if (!isAuthenticated) {
			return false;
		}

		//if we haven't listened to a text at least two times, don't ask
		if ((state.usageCount || 0) < 2) {
			return false;
		}

		//if already asked for permission, ignore
		if (alreadyAsked()) {
			return false;
		}

		//if already answered smth to the native dialog, ignore
		const permission = window.Notification.permission;
		if (permission !== 'default') {
			return false;
		}
		
		return true;
	}

	function alreadyAsked() {
		return !!window.localStorage.getItem(STORAGE_NAME);
	}

	function saveAlreadyAsked() {
		window.localStorage.setItem(STORAGE_NAME, true);
		/* eslint-disable */
		window.analytics?.track('Push', answer);
	}

	//display the native dialog if we agreed
	async function askNative(answer) {
		if (answer) {
			const permission = await window.Notification.requestPermission();
			if (permission === 'granted') {
				await subscribeToPush();
			}
		}
	}
	
	async function subscribeToPush(){
		try {
			let registration = await navigator.serviceWorker.ready;
	
			const PUBLIC_KEY = "BAC-E8lkR37Qa93CWMvLgEWSVYL0Hxl9j-x17b0XNAjXJb0EpWIdS0nl7ZhRUWjtAO6YTNU8uoPHNSzeprZ317g";
			const applicationServerKey = urlB64ToUint8Array(PUBLIC_KEY);
			const options = { applicationServerKey, userVisibleOnly: true };
			const subscription = await registration.pushManager.subscribe(options);

			await axios.post('/UserManagement/Notifications/Push', subscription);
		} catch (err) {
			console.error('Error', err);
		}
	}
	
	function urlB64ToUint8Array(base64String) {
	
		const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
		const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')
		const rawData = atob(base64)
		const outputArray = new Uint8Array(rawData.length)
		for (let i = 0; i < rawData.length; ++i) {
			  outputArray[i] = rawData.charCodeAt(i)
		}
		return outputArray
	}
	
}

export default withTranslation()(AskForPush);
