import queryString from 'query-string';

const learnedLang = useQueryString('learn', 'lt');
const langData = {nom: `lang-${learnedLang}-nom`, gen: `lang-${learnedLang}-gen`, learnedLang};

export default langData;


function useQueryString(name, defaultValue){
	let params = queryString.parse(window.location.search);
	return params[name] || defaultValue;
}