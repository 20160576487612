import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from "@auth0/auth0-react";
import {Link} from 'react-router-dom';
import texts from './history/texts.svg';
import bonuses from './history/bonuses.svg';
import payments from './history/payments.svg';
import support from './history/support.svg';
import exit from './history/exit.svg';
import { Context } from '../../common/Context';
import RedDot from './RedDot';


const AccountMenu = (props) => {

	const { logout, isAuthenticated } = useAuth0();

	const { t } = useTranslation();

	const {state: {serverUser: user, serverAuthenticated} , dispatch} = React.useContext(Context);

	if(!isAuthenticated || !user)
		return null;

	return (
		<div className="userMenu">
			<Link to="/User/MyTexts" onClick={() => hideMenu()}>
				<h1>
					<div className="iconWrapper">
						<div className="iconBg" title={t("my-texts-title")}>
							<img src={texts} alt={t("my-texts-title")} className="icon"/>
						</div>
					</div>

					{t("my-texts-title")}
				</h1>
			</Link>


			{!user.free && 
				(
					<Link to="/User/MyBonuses" onClick={() => hideMenu()}>
						<h1>
							<div className="iconWrapper">
								<div className="iconBg" title={t("my-bonuses-title")}>
									<img src={bonuses} alt={t("my-bonuses-title")} className="icon"/>
								</div>
							</div>

							{t("my-bonuses-title")}
						</h1>
					</Link>
				)
			}

			{!user.free &&
				(
					<Link to="/User/MyPayments" onClick={() => hideMenu()}>
						<h1>

							<div className="iconWrapper">
								<div className="iconBg" title={t("my-payments-title")}>
									<img src={payments} alt={t("my-payments-title")} className="icon"/>
								</div>
							</div>

							<div style={{display: 'inline-flex'}}>
								{t("my-payments-title")}
							</div>							
						</h1>
					</Link>
				)
			}

			{user.teacher && 
				(
					<Link to="/User/TeacherLink" onClick={() => hideMenu()}>
						<h1>
							<div className="iconWrapper">
								<div className="iconBg" >
									<i className="icon-user-pair"></i>
								</div>
							</div>
							
							{t("teacher-link-title")}
						</h1>
					</Link>						
				)
			}

			<Link to="/User/MyReminders" onClick={() => hideMenu()}>
				<h1>
					<div className="iconWrapper">
						<div className="iconBg" title={t("my-reminders-title")}>
							<BellIcon/>
						</div>
					</div>

					<div style={{display: 'inline-flex'}}>
						{t("my-reminders-title")}
						{/* <div></div> */}
					</div>

					<RedDot screen="reminders"/>
				</h1>
			</Link>

			<Link to="/User/MyPhrases" onClick={() => hideMenu()}>
				<h1>
					<div className="iconWrapper">
						<div className="iconBg" title={t("my-phrases-title")}>
							<i className="icon-bookmark-empty"></i>
						</div>
					</div>
					
					<div style={{display: 'inline-flex'}}>
						{t('my-phrases-title')}
					</div>

					<RedDot screen="phrases"/>
				</h1>
			</Link>

			<Link to="/User/Support" onClick={() => hideMenu()}>
				<h1>
					<div className="iconWrapper">
						<div className="iconBg" title={t("my-support-title")}>
							<img src={support} alt={t("my-support-title")} className="icon"/>
						</div>
					</div>

					{t("support-title")}
				</h1>
			</Link>


			<Link to="/" onClick={() => { hideMenu(); logoutCustom(); }}>
				<h1>
					<div className="iconWrapper">
						<div className="iconBg" title={t("logout")}>
							<img src={exit} alt={t("logout")} className="icon"/>
						</div>
					</div>
					
					{t("logout")}
				</h1>
			</Link>

		</div>
	)

	function hideMenu() {
		setMenuVisibility(false);
	}

	function logoutCustom() {
		localStorage.removeItem('userInfo');
		localStorage.removeItem('ajs_user_id');
		logout({localOnly: true});
	}

	function setMenuVisibility(userMenuVisible){
		dispatch({
			type: 'MENU_VISIBLE',
			payload: { userMenuVisible }
		})
	}
}



export default AccountMenu;

function BellIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className='icon'>
		<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
		</svg>
	)
}