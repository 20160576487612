import React, { Component } from "react";
import { TrackJS } from "trackjs";

if (window.location.host.indexOf("localhost") !== 0) {
    TrackJS.install({
        token: "42da172b655549ffbaad02e9eec67b81",
        userId: getUsername()
    });    
}

  

export default class ErrorBoundary extends Component {
    state = {};

    componentDidCatch(error, errorInfo) {
		debugger;
        if (errorInfo && errorInfo.componentStack) {
            // The component stack is sometimes useful in development mode
            // In production it can be somewhat obfuscated, so feel free to omit this line.
            console.error(errorInfo.componentStack);
        }

        TrackJS.track(error);
    }
    static getDerivedStateFromError(error) {
		debugger;
        // Update state so the next render will show the fallback UI.
        return { error };
    }    

    render() {
        if (this.state.error) {
            return (
                <div style={{fontSize: 'large', color: '#cc2222'}}>
                    <p>There has been an error -- we're so sorry about that!</p>
                    <p>We are notified and working on this.</p>
					<span className="btn btn-big btn-success" onClick={() => {window.location.reload(true);}}>Reload</span>
                </div>
            )
        } else {
            return this.props.children; 
        }
         
    }
}

function getUsername() {
    let userInfo = localStorage.getItem('userInfo');
    return userInfo && JSON.parse(userInfo).name;
}
