import React from 'react'
import { Panel } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Context } from '../../../common/Context';

const PhraseNavigation = (props) => {

    const {state, dispatch} = React.useContext(Context);
    const [open, setOpen] = React.useState(false);
    return (
        <Panel id="phraseNavWrapper" expanded={open} onToggle={()=>{}}>
            <Panel.Heading onClick={() => setOpen(!open)} style={{cursor: 'pointer'}}>
                <a>
                    <h4>{props.t("player-quicknav")}</h4>
                </a>
            </Panel.Heading>

            <Panel.Collapse id="phraseNav">
                <Panel.Body style={{marginTop: '5px'}}>

                    {props.normalTranslatedPhrases.map((phraseText, index) => {
                        let isCurrent = (index === (state.phraseIndex || 0));
                        return (<div key={index} className={"captionLink" + (isCurrent? ' captionLinkActive' : '')} onClick={() => navigate(index)}>{phraseText}</div>)
                    })}

                </Panel.Body>

            </Panel.Collapse>
        </Panel>

    )

    function navigate(index){
        dispatch({ type: 'FORCE_INDEX', payload: {forcedIndex: index} });
        window.scroll({
            top: 0,
            behavior: "smooth"   // Optional, adds animation
		});
		window.scroll(0, 0);	//Safari support
    }
}

export default withTranslation()(PhraseNavigation);
