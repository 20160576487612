import React from 'react';
const allAB = {};

export default function useAB(key, numVersions = 2) {
	const [version] = React.useState(() => {
		let version = localStorage.getItem(key);
		if (!version) {
			version = getRandomVersion(numVersions);
			localStorage.setItem(key, version);
		}
		allAB[key] = version;
		return version;
	});
	return version;

	function getRandomVersion(max) {
		let randomInt = Math.floor(Math.random() * max);
		return String.fromCharCode('A'.charCodeAt(0) + randomInt);
	}
}

export function getAll(){
	return allAB;
}
