import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import langData from '../../common/multilang';

const Already = () =>  {
	const { t } = useTranslation();

	return (
		<div className="row">
			<div className="home-content col-sm-6">
				<div className="already">
					<h2>
						{t('home-already-header', {lang: t(langData.nom)})}
					</h2>
					<div className="home-description">
						{ReactHtmlParser(t('home-already-text', {lang: t(langData.gen)}))}
					</div>
				</div>
			</div>

			<div className="home-content col-sm-6" style={{backgroundColor: '#ebebeb'}}>
				<div className="teacher">
					<h2>
						{t('home-teacher-header', {lang: t(langData.gen)})}
					</h2>
					<div className="home-description">
						{ReactHtmlParser(t('home-teacher-text'))}
					</div>
				</div>
			</div>

		</div>
	)
}

export default Already;
