import React, { Component } from 'react';
import { Context } from '../../../common/Context';
import Draggable from 'react-draggable';

const PlayerProgress = (props) => {

    const {state, dispatch} = React.useContext(Context);
    const [ratio, setRatio] = React.useState(0);
    const [position, setPosition] = React.useState({x:0, y:0});
    const playerProgressWrapper = React.useRef(null);
    const playerPosition = React.useRef(null);

    React.useEffect(() => {
        if (props.duration) {
            const ratio = state.playedPosition / props.duration;
            setRatio(ratio);

            let x = ratio * (playerProgressWrapper.current.clientWidth - playerPosition.current.clientWidth);
            setPosition({x, y:0});
            // console.log("componentDidUpdate",x);

            //TODO: handle resize
            // componentDidMount() {
            //     window.addEventListener('resize', this.updateDimensions);
            // }
            // componentWillUnmount() {
            //     window.removeEventListener('resize', this.updateDimensions);
            // }
        }
    }, [state.playedPosition, props.duration]);

    let progressPercentage = (ratio * 100).toString() + '%';
    return (
        <div id="playerProgressWrapper" ref={playerProgressWrapper}>
            <div id="playerProgressBackground" onClick={e => bgClicked(e)}></div>
            <div id="playerProgress" style={{width: progressPercentage}} onClick={bgClicked}></div>
            <div id="playerProgressFixedPart" style={{ left: progressPercentage }}></div>

            <Draggable axis="x" bounds='parent' onDrag={dragging} position={position}>
                <div id="playerPosition" ref={playerPosition}></div>
            </Draggable>
        </div>

    )


    function bgClicked(e){
        let x = e.nativeEvent.offsetX - 10; //-half of the playerPosition
        let totalWidth = e.target.offsetParent.clientWidth; //note that this covers both playerProgressBackground and playerProgress
        let ratio = x / totalWidth;
        advertizePosition(ratio);
        setRatio(ratio);
        setPosition({x, y: 0});
    }


    function dragging(e, dragData){
        let draggedElement = dragData.node;
        let totalWidth = draggedElement.offsetParent.clientWidth;
        let ratio = dragData.x / totalWidth;
        advertizePosition(ratio);
        setRatio(ratio);
        setPosition({x: dragData.x, y: 0});
        console.log("PlayerProgress dragging", dragData.x);
        console.log("ratio", ratio);
    }

    function advertizePosition(ratio) {
        var newPosition = ratio * props.duration;
        dispatch({ type: 'FORCED_POSITION', payload: {forcedPosition: newPosition, playerState: 'seeking'} });
    }
}

export default PlayerProgress;
