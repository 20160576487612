import React from 'react';
import { Context } from '../../common/Context';


const Login = () => {
	const {dispatch} = React.useContext(Context);
	React.useEffect(() => { forceLogin(); }, []);	
	return null;

	function forceLogin(){
		dispatch({
			type: 'FORCE_LOGIN',
			payload: {forceLogin: true}
		});
	}
}

export default Login;
