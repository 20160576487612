import React, { Component } from 'react';
import { connect } from "react-redux";
import { withTranslation, useTranslation } from 'react-i18next';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import texts from './history/texts.svg';
import { Context } from '../../common/Context';
import Spinner from '../../common/ui/Spinner';
import { useAuth0 } from '@auth0/auth0-react';


const TeacherLink = (props) => {
	const [copied, setCopied] = React.useState(false);
	const { isAuthenticated } = useAuth0();
	const { state: {serverUser: user, serverAuthenticated}, dispatch } = React.useContext(Context);
	const { t } = useTranslation();

	if (!isAuthenticated || !serverAuthenticated) {
		return <Spinner/>;
	}

	const link = `https://flyent.online/?ref=${user?.referralCode}&utm_medium=referral`;

	return (
		<React.Fragment>
			<h1>
				<img src={texts} alt={t("my-texts-title")} className="icon"/>
				{t('teacher-link-title')}
			</h1>

			<div className="userPageText" style={{margin: '1em 0'}}>{t('teacher-link-description')}</div>

			<InputGroup>
				<FormControl type="text" value={link} readOnly/>
				<InputGroup.Button>
					<Button onClick={() => copy()}>
						{t('copy-inf')}
						{copied? 
						<FontAwesomeIcon icon={faCheck} className="copyIcon"/>
						:
						<FontAwesomeIcon icon={faCopy} className="copyIcon"/>
						}
					</Button>
				</InputGroup.Button>
			</InputGroup>
		</React.Fragment>
	)


	async function copy() {
		await navigator.clipboard.writeText(link);
		setCopied(true);
	}
}


export default TeacherLink;
