
import React from 'react';
import { useQuery } from 'react-query';
import { Context } from './Context';

export default function useQueryAuthenticated(queryKey, queryFn, config){
	const { state: {serverAuthenticated} } = React.useContext(Context);
	if (!Array.isArray(queryKey)) {
		queryKey = [queryKey];
	}
	queryKey.push(serverAuthenticated);
	const authenticatedFn = async () => { return await (serverAuthenticated && queryFn()); };
	let { isLoading, isSuccess, data } = useQuery(queryKey, authenticatedFn, config);
	if (!serverAuthenticated) {
		isLoading = true;
		isSuccess = false;
	}
	return { isLoading, isSuccess, data }
}
