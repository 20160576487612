import React from 'react';
import { useTranslation } from 'react-i18next';
//import { Context } from '../../../common/Context';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from 'react-query';
import axios from 'axios';
import { Link } from 'react-router-dom';


const Subscription = ({subscribedUntil}) => {
	//const { state, dispatch } = React.useContext(Context);
	const { t, i18n } = useTranslation();
	const isProduction = (process.env.NODE_ENV === 'production');
	const mutation = useMutation(unsubscribe);

	if (mutation.isSuccess) {
		return (
			<>
				<h1>{t('unsubscribe-success')}</h1>
				<Link to="/Students/Start" className="btn btn-success" style={{marginTop: '2em'}}>
					{t('pay-success-back')}
				</Link>
			</>
		)
	}

	subscribedUntil = new Date(subscribedUntil).toLocaleDateString(i18n.language, { year: 'numeric', month: 'long', day: 'numeric' });
	return (
		<main>
			<h1>{t('unsubscribe-subscribeduntil', {subscribedUntil})}</h1>

			<div className="btn btn-danger" onClick={mutation.mutate} style={{fontSize: '2rem', margin: '1em'}}>{t('unsubscribe-cta')}</div>

			<Link to="/Students/Start" className="btn btn-success" style={{fontSize: '2rem', margin: '1em'}}>
				{t('pay-success-back')}
			</Link>

			{mutation.error && <div className="pay-error">
					{mutation.error.response.data.Message}
				</div>}

		</main>
	)

	async function unsubscribe(){
		window.analytics?.track("Unsubscribed");
		const url = `/UserManagement/Payments/unsubscribe?isProduction=${isProduction}`;
		await axios.post(url);
	}
}

export default Subscription;
