import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



const TopStartButton = (props) => {
	const { t } = useTranslation();

	
	return (

		<div id="startButtonRow">
			<span className="startButtonContainer">
				<div className="vcenter">
					{!props.preview && (
					<Link className="btn" to="/Students/Start" style={{verticalAlign: 'middle'}}>
						<span className="try">{t("home-try")}</span>
						<span> </span>
						<span className="free">{t("home-free")}</span>
					</Link>)}

					{props.preview && (
					<span className="btn" onClick={() => {props.setShowEmailDialog(true);}} style={{verticalAlign: 'middle'}}>
						<span className="try">{t("home-try")}</span>
						<span> </span>
						<span className="free">{t("home-free")}</span>											
					</span>)}
				</div>
			</span>
		</div>
	)
}

export default TopStartButton;
