import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FormControl, InputGroup, Button } from 'react-bootstrap';
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SocialIcon } from 'react-social-icons';


class BonusLink extends Component {
	state = {
		copied: false
	};

	link = `https://flyent.online/?ref=${this.props.referralCode}&utm_medium=referral`;

	render() {
		let sharedText = this.props.t('slogan2').replace(/&nbsp;/g, ' ') + ": " + this.link;
		return (
			<React.Fragment>

				<InputGroup>
					<FormControl type="text" value={this.link} readOnly/>
					<InputGroup.Button>
						<Button onClick={() => this.copy()}>
							{this.props.t('copy-inf')}
							{this.state.copied? 
							<FontAwesomeIcon icon={faCheck} className="copyIcon"/>
							:
							<FontAwesomeIcon icon={faCopy} className="copyIcon"/>
							}
						</Button>
					</InputGroup.Button>
				</InputGroup>

				<div className="social">

					<SocialIcon url={"https://twitter.com/intent/tweet?text=" + encodeURIComponent(sharedText)} style={{ height: 50, width: 50 }} target="_blank"/>

					<SocialIcon url={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(this.link)}&amp;src=sdkpreparse`} style={{ height: 50, width: 50 }} target="_blank"/>
				
				</div>
			</React.Fragment>
		)
	}

	async copy() {
		await navigator.clipboard.writeText(this.link);
		this.setState({copied: true});
	}
}

export default withTranslation()(BonusLink);