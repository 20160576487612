import React from 'react';
import AccountMenu from './AccountMenu';
import { Context } from '../../common/Context';


const AccountMenuDialog = () => {

	const {state} = React.useContext(Context);

	return (
		<div className={`accountMenuDialog ${state.userMenuVisible? "visible": ""}`}>
			<AccountMenu/>
		</div>
	)


}

export default AccountMenuDialog;
