import React from 'react';
import { withRouter } from 'react-router';
import i18next from 'i18next';
import { Dropdown, MenuItem } from 'react-bootstrap';
import lang_switcher from './lang_switcher.svg';
import { Context } from '../Context';


const LangSwitcher = (props) => {
    //1. state loaded from store
    //2. default is LT + localization: choose between ru and en
    //3. on change: save to store and redirect to the list
	//4. if visited a link, adds to available pairs(?) and save to store

	const {state: {langData, languagePairs: availablePairs}, dispatch} = React.useContext(Context);
	
	if (!availablePairs) {
		return null;
	}

	return (
		<Dropdown id="langChooser" className="langChooser">
			<LangPair {...langData} bsRole="toggle"/>

			<CustomMenu bsRole="menu">
				{availablePairs.map((pair, index) => {
					return (
						<MenuItem
								eventKey={pair}
								key={index}
								onSelect={langData => langChosen(langData)}
							><LangPair {...pair}  />
						</MenuItem>
					)
				})}
			</CustomMenu>
		</Dropdown>

	)


    async function langChosen(langData){
		dispatch({ type: 'SELECTED_LANG', payload: { langData } });
		localStorage.setItem('langData', JSON.stringify(langData));
		await i18next.changeLanguage(langData.knownLang);
		props.history.push(`/Students/Start`);
    }


}

export default withRouter(LangSwitcher);

//draws two circles with languages
class LangPair extends React.Component {
	render() {
		return (
			<div onClick={e => this.props.bsRole==="toggle" && this.props.onClick(e)} className="langPair">

				<img src={lang_switcher} className="langSwitcherBg"/>

				<div className="lang learnedLang">{this.props.learnedLang}</div>
				<div className="lang knownLang">{this.props.knownLang}</div>

			</div>
		)		
	}


}

//important to keep this a class component because otherwise Dropdown for Bootstrap v3 can't work with it
class CustomMenu extends React.Component {
    render() {
        return (
            <ul className="dropdown-menu" id="langChooserMenu" style={{ padding: '' }} onClick={(e) => this.props.onSelect(null, e)}>
                {this.props.children}
            </ul>
        );
    }
}

// const MenuOpener = (props) => {

// 	return (
// 		<button onClick={e => props.onClick(e)} type="button" className="btn" id="langChooserOpener">
// 			{/* <span className="caret" style={{color: '#F4FAF2'}}></span> */}
// 			<span className="sr-only">Toggle Dropdown</span>
// 		</button>
// 	)
// }
