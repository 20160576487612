import React, { Component } from 'react';
import { Panel, FormControl } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import axios from 'axios';

class Excercise extends Component {
	state = { open: false };

	async componentDidMount(){
		const name = this.props.name;
		let url = `/Teachers/Words/${name}?translationTypes=base`;
		let response = await axios.get(encodeURI(url));
		// let data = {"phrases":[{"base":{"phrase":null,"words":[{"original":"Karolina","translated":"Karolina","changed":false},{"original":"Čia","translated":"Čia","changed":false},{"original":"tu","translated":"tu","changed":false}]}},{"base":{"phrase":null,"words":[{"original":"Sauliau","translated":"Saulius","changed":false},{"original":"Šimtas","translated":"Šimtas","changed":false},{"original":"metų","translated":"metas","changed":false}]}},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null},{"base":null}],"title":"Karolina","translatedTitle":null,"translatedDescription":null,"moduleName":"dialogai","translatedModuleName":null,"isFinished":false};
		let data = response.data;
		this.setState(data);
	}

    render() {
		if (!this.state.phrases) {
			return null;
		}

        return (
			<Panel id="exercisePanel" className="exercise" expanded={this.state.open} onToggle={()=>{}}>
				<Panel.Heading  onClick={() => this.setState({ open: !this.state.open })}>
					<a>
                        <h4>{this.props.t("Exercise")}</h4>
                    </a>
				</Panel.Heading>

				<Panel.Collapse>
				<Panel.Body>
				{this.props.entries.map((entry, phraseIndex) => this.renderPhrase(entry.text, phraseIndex, this.state.phrases[phraseIndex].base))}
				</Panel.Body>
				</Panel.Collapse>
			</Panel>
        )
	}

	renderPhrase(text, phraseIndex, baseData){
		if (!baseData || !text) {
			return null;
		}

		const wordsData = baseData.words;
		let words = text.trim().split(' ');
		const regex = new RegExp('\\p{L}', 'u');		//matches chars
		words = words.filter(word => regex.test(word));	//filter out "words" like "-"
		return (
			<div key={phraseIndex} className="exercise-phrase">
				{words.map((word, wordIndex) => (<ExcerciseWord word={word} base={wordsData[wordIndex].translated} key={wordIndex}/>))}
			</div>
		)
	}

}

class ExcerciseWord extends Component {
	state = { value: "", isMatch: null};
	regex = new RegExp('\\p{L}*', 'u');		//matches words including accented chars

	render() {
		//base is like "metas", word is like "metų!", original is like "metų", postfix is like "!"
		let original = this.props.word.match(this.regex)[0];
		if (original.toLowerCase() === this.props.base.toLowerCase()) {
			return (<React.Fragment>{this.props.word} </React.Fragment>)
		} else {
			let postfix = this.props.word.substring(original.length);
			let isMatch = this.isMatch(this.state.value, original);
			let textClass = this.textClass(isMatch);
			return (
				<React.Fragment>
					<FormControl
						type="text"
						placeholder={this.props.base}
						bsSize="sm"
						size={original.length}
						className={textClass}
						value={this.state.value}
						onChange={e => this.setState({ value: e.target.value })}
						/>
					{postfix + " "}
				</React.Fragment>
			)

		}
	}

	isMatch(value, correctValue) {
		if(value === correctValue)
			return true;
		if (!correctValue.startsWith(value)) {
			return false;
		}
	}

	textClass(isMatch) {
		switch (isMatch) {
			case true:
				return "correct"
				break;
			case false:
				return "incorrect"
			default:
				break;
		}
	}
}

export default withTranslation()(Excercise);