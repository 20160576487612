import React from 'react';
import DialogWrapper from '../../../../common/DialogWrapper';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

const BuyTexts = (props) => {
	return (
		<DialogWrapper show={props.show} onOK='/Payments/Pay' title="Please pay" cta="pay-buy">
			{ReactHtmlParser(props.t('BuyTexts'))}
		</DialogWrapper>
	)
}

export default withTranslation()(BuyTexts);
