import React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Spinner from '../../../common/ui/Spinner';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../account.scss';
import SmileAndBuy from './SmileAndBuy';
import texts from './texts.svg';
import ReactHtmlParser from 'react-html-parser';
import { Context } from '../../../common/Context';
import useSetVisited from '../dot_visited';
import { useAuth0 } from '@auth0/auth0-react';

const MyTexts = (props) => {

	const [data, setData] = React.useState(null);
	const [credits, setCredits] = React.useState(null);

	React.useEffect(() => { loadHistory(); }, [])
	React.useEffect(() => { loadRemainingCredits(); }, []);
	useSetVisited('texts');

	const { isAuthenticated } = useAuth0();
	const { state: {serverUser: user, serverAuthenticated}, dispatch } = React.useContext(Context);
	const { t } = useTranslation();

	if (!data || !isAuthenticated || !user) {
		return <Spinner/>;
	}

	return(
		<React.Fragment>
			<h1>
				<img src={texts} alt={t("my-texts-title")} className="icon"/>
				{t('my-texts-title')}
			</h1>

			{data.length === 0 && (
				<div className="noRecords">
					{t('my-texts-empty')}
				</div>
			)}

			{data.length > 0 && (
				<div className="table-scroller">

						<Table condensed className="data">
							<thead>
								<tr>
									<th colSpan="2">{t('my-texts-listened')}</th>
								</tr>
							</thead>

							<tbody>
								{data.map((item, index) => renderRow(item))}
							</tbody>

						</Table>					

				</div>
			)}


			{!user.free && (
				<>
				<Table condensed className="data">
					<thead>
						<tr>
							<th colSpan="2">{t('my-texts-remains')}</th>
						</tr>						
					</thead>

					<tbody>
						<tr>
							<td colSpan="2" className="remains">{ReactHtmlParser(getRemainingItemsText(credits))}</td>
						</tr>						
					</tbody>
				</Table>

				<SmileAndBuy/>
				</>			
			)}

		</React.Fragment>
	)

	async function loadHistory() {
		if (isAuthenticated && !data) {
			const url = '/UserManagement/usageHistory';
			let newData = (await axios.get(url)).data;
			setData(newData);
		}
	}

	async function loadRemainingCredits(){
		if (isAuthenticated && (credits === null)) {
			let response = await axios.get('/UserManagement/Credits/total');
			let newCredits = parseInt(response.data);
			setCredits(newCredits);
		}		
	}

	function renderRow(item) {
		return (
			<tr key={item.itemName}>
				<td className="title">
					<Link to={`/Students/Player/${item.itemName}?lang=${item.lang}`}>
						{item.title}
					</Link>
				</td>

				<td className="count">
					{item.usageCount} {t('my-texts-times', {count: item.usageCount})}
				</td>
			</tr>
		)
	}

	function getRemainingItemsText(count){
		if (count > 0) {
			return t('my-texts-remains-count', { count });
		} else {
			return t('my-texts-remains-zero');
		}
		
	}

}


export default MyTexts;