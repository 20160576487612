import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

class HelpDialog extends Component {
    render() {
		if (this.props.show) {
			window.localStorage.setItem('flyent_HelpShown', true);	//remember that we have shown the dialog at least once
		}
        return (
            <Modal show={this.props.show} onHide={this.props.onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.t('HelpDialogTitle')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {ReactHtmlParser(this.props.t('HelpDialogText'))}
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn btn-big btn-success" color="primary" onClick={this.props.onClose}>
                        {this.props.t('DialogClose')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default withTranslation()(HelpDialog);
