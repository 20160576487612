import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import HelpDialog from './HelpDialog';
import './help.scss';

class HelpButton extends Component {
    state = { showDialog: false };

    toggleDialog = visibility => {
		this.setState({ showDialog: visibility });
	}

    render() {
        return (
            <React.Fragment>
                <button className="btn btn-sm vcenter" id="helpButton" onClick={() => this.toggleDialog(true)}>
                    {this.props.t('HelpDialogTitle')}
                </button>

                <HelpDialog show={this.state.showDialog} onClose={() => this.toggleDialog(false)}/>
            </React.Fragment>
        )
    }
}

export default withTranslation()(HelpButton);
