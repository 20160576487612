import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Context } from '../../../common/Context';

const Item = (props) => {
	const {dispatch} = React.useContext(Context);
	const { t } = useTranslation();

    const learnUrlTemplate = '/Students/Player/{name}?lang={lang}';

	props.item.displayedTitle = getTitle(props.item);	//so that it is passed to the store
	
	return (
		<Link
			to={getLearnUrl()}
			onClick={() => setCurrentItem(props.item)}
			className="item"
		>

			{props.item.isNew && (
				<div className="list-item-new">new</div>
			)}
			
			<div>
				<div>
					<div className="level">
						{props.item.favorite && <i className="icon-heart"></i>}
						<div className={"levelDigit levelDigit" + props.item.contentItem.level}>
							{props.item.contentItem.level}
						</div>

						<div className="levelWord">
							{t('level')}
						</div>
					</div>
					
					<h4 className="title">
						{getTitle()}
					</h4>
				</div>

				<div className="description">{getDescription()}</div>



			</div>
		</Link>
	);


    function getTitle() {
        let item = props.item;
        return item.translation.title || item.contentItem.title || item.contentItem.name;
    }

    function getDescription() {
        let item = props.item;
        return item.translation.description || item.contentItem.description;
    }

    function getLearnUrl() {
        let item = props.item;
        let name = item.contentItem.name;
        let lang = item.translation.lang || '';
        return learnUrlTemplate.format({name, lang});
    }

	function setCurrentItem(item) {
		dispatch({
			type: 'SET_ITEM',
			payload: {
				currentItem: item
			}
		});
	}
}

export default Item;
