import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {Context} from '../../../../common/Context';
import BuyTexts from './BuyTexts';


const ShouldPayV2 = (props) => {
	const {state, dispatch} = React.useContext(Context);
	
	return (
		<BuyTexts show={state.shouldPay}/>
	)
}

export default withTranslation()(ShouldPayV2);
