import React from 'react';
import { useTranslation } from 'react-i18next';



const DownloadText = ({model}) => {
	const { t } = useTranslation();
	return (
		<div className="col-xs-6 panel-heading downloadText" style={{marginLeft: '50%'}}>
            <a href={`/Teachers/Words/${model.name}/download_translation?lang=${model.lang}`}>
                <h4>
                    {t("player-download-text")}
                </h4>
            </a>
        </div>
	)
}

export default DownloadText;
