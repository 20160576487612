import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import ball from './ball.svg';
import watch from './tik-tok.svg';

class NoNeed extends Component {
    render() {
        return (
			<div className="no-need row">

				<div className="no-need-item col-sm-6">

					<img src={ball} className="no-need-image ball"/>

					<div className="no-need-description">
						<h2>
							{ReactHtmlParser(this.props.t('noneed-effort-header'))}
						</h2>
						{ReactHtmlParser(this.props.t('noneed-effort-text'))}
					</div>
				</div>

				<div className="no-need-item col-sm-6">
					<img src={watch} className="no-need-image watch"/>

					<div className="no-need-description">
						<h2>
							{ReactHtmlParser(this.props.t('noneed-time-header'))}
						</h2>
						{ReactHtmlParser(this.props.t('noneed-time-text'))}
					</div>
				</div>
			</div>
        )
    }
}

export default withTranslation()(NoNeed);
