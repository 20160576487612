import React from 'react';
import { Context } from '../../../common/Context';

const Looper = (props) => {

    const {state, dispatch} = React.useContext(Context);
    const [enabled, setEnabled] = React.useState(false);
    const [index, setIndex] = React.useState();

    //if enabled, set the index
    React.useEffect(() => {
        if (enabled) {
            setIndex(state.phraseIndex);
        }
    }, [enabled]);

    //on phrase change, get back to this index
    React.useEffect(() => {
      if (enabled && props.playerState === 'playing') {
        // console.log("Loopback")
        dispatch({ type: 'FORCE_INDEX', payload: {forcedIndex: index} });
      }     
    }, [state.phraseIndex]);


    return (

      <div id="repeaterWrapper">
        <button className={"btn btn-sm player-btn" + (enabled? " enabled": "")} id="repeater" onClick={() => setEnabled(!enabled)} data-toggle="button" aria-pressed="false" title="слушать по кругу">
          <i className="icon-cw"></i>
        </button>

      </div>

    )
	//an utility function to use instead of prevprops
	function usePrevious(value) {
		const ref = React.useRef();
		React.useEffect(() => {
		  	ref.current = value;
		});
		return ref.current;
	}

}
export default Looper;