import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

class BonusHelp extends Component {
    render() {
        return (
            <React.Fragment>

                <div className="bonus-container bonus-container-yellow">
					{ReactHtmlParser(this.props.t('BonusHelp-first10'))}
				</div>

                <div className="bonus-container bonus-container-blue">
					{ReactHtmlParser(this.props.t('BonusHelp-buy'))}
				</div>

                <div className="bonus-container bonus-container-green">
					<div>
						{ReactHtmlParser(this.props.t('BonusHelp-free'))}
					</div>

					<div className="bonus-number">1</div>

					<div className="bonus-help">
						{ReactHtmlParser(this.props.t('BonusHelp-listen'))}
					</div>

					<div className="bonus-number">2</div>

					<div className="bonus-help">
						{ReactHtmlParser(this.props.t('BonusHelp-friend'))}
					</div>
				</div>

            </React.Fragment>
        )
    }
}

export default withTranslation()(BonusHelp);
