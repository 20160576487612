import React from 'react';
import { connect } from "react-redux";
import { Context } from '../../../common/Context';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import { useTranslation } from 'react-i18next';
const events = require('dom-events');


const PlayerControls = (props) => {

	const [playbackRate, setPlaybackRate] = React.useState(1);
	const [playing, setPlaying] = React.useState(false);
	const [currentIndex, setCurrentIndex] = React.useState();
	const [lastTranslationIndex, setLastTranslationIndex] = React.useState();	//so that we don't play the same translation twice
	const [wakeLock, setWakeLock] = React.useState(null);
	const mediaRef = React.useRef(null);
	const translatedMediaRef = React.useRef(null);

	const ctx = useLocalContext({playing, currentIndex, lastTranslationIndex, ...props});		//so that closures from event handlers don't use stale data
	const {state, dispatch} = React.useContext(Context);
	
	const { t } = useTranslation();

	// console.log(`Rerender; currentIndex = ${currentIndex}; playing=${playing}`);

	//this is run only once
	React.useEffect(() => { 
		updateMediaState();
		setupEvents();
	}, [props.model]);

	//set/unset button handlers
	React.useEffect(() => {
		handleMediaButtons();
		return resetMediaButtons;
	}, []);

	//if a dialog is open, stop playing
	React.useEffect(() => {
		if (state.dialogVisible) {
			pause();
		}
	}, [state.dialogVisible]);

	//remove Wake lock on unmount
	React.useEffect(() => releaseWakeLock, []);


	function setupEvents(){
		//only setup when everything's been fully loaded
		if (props.model.captions.entries.length === 0) {	//this happens when we got here from the List and have some preliminary data to show to the user
			return;
		}

        //with each change of the media's currentTime, update the state and notify the parent
        events.on(mediaRef.current, 'timeupdate', async () => {
            if(!mediaRef.current) return;     //fixing a weird error when navigating away
            await playTranslationIfNeeded();
			updateMediaState();
			dispatch({ type: 'PLAY_PROGRESS', payload: {playedPosition: mediaRef.current.currentTime}});
        });

        // //when the media's loaded, notify the parent (so that it knows the duration)
        // events.on(mediaRef.current, 'durationchange', () => {
		// 	if (mediaRef.current) {
		// 		updateMediaState();
		// 		props.onPlayProgress(mediaRef.current.currentTime, mediaRef.current.duration);				
		// 	}
        // });

        //notify the parent about verious events
        'play playing pause seeking seeked phraseChange ended'.split(' ').forEach(eventName => {
            events.on(mediaRef.current, eventName, () => {
                props.onStateChange(eventName, ctx.playing);	//use ctx inside a closure
                // trackEvent(eventName)
                console.log(eventName);
            });

		});
		
		//prevent screen from locking
		events.on(mediaRef.current, 'play', async () => {
			let newWakeLock = await navigator.wakeLock?.request('screen');
			setWakeLock(newWakeLock);
		});

		events.on(mediaRef.current, 'pause', async () => {
			await wakeLock?.release();
			setWakeLock(null);
		});

		//play translation on start
		events.on(mediaRef.current, 'play', async () => {
			await playTranslationOnStart();
		});
	}

	//handle forced change of position
	React.useEffect(() => {
		if (state.forcedPosition !== undefined) {
			mediaRef.current.currentTime = state.forcedPosition;
			dispatch({ type: 'FORCE_POSITION', payload: {forcedPosition: undefined} });	//reset
		}		
	}, [state.forcedPosition]);
	
	//same with forcedIndex
	React.useEffect(() => {
		if (state.forcedIndex !== undefined) {
			if (state.forcedIndex === 0) {	//when we just opened the Player, entries are not loaded yet, so we just set it to 0
				mediaRef.current.currentTime = 0;								
			} else {
				let entry = props.model.captions.entries[state.forcedIndex];
				mediaRef.current.currentTime = entry.time;				
			}
			dispatch({ type: 'FORCE_INDEX', payload: {forcedIndex: undefined} });	//so that we could force it to the same index		
		}
	}, [state.forcedIndex]);

    //analytics
    function trackEvent(eventName){
		window.analytics && window.analytics.track(eventName, {
			itemName: props.model.name,
			lang: props.model.lang
		});
    }


    async function releaseWakeLock(){
		await wakeLock?.release();
		setWakeLock(null);
    }

    function isplaying() {
        if (!mediaRef.current) {
            return false;
        }
        return !mediaRef.current.paused;
    }

	//read the state of the media control and set the state accordingly
    function updateMediaState() {
		setPlaying(isplaying());

        //handle index change 
		let newIndex = getCurrentIndex();
		// console.log(currentIndex, ctx.currentIndex, newIndex, isplaying(), playing, ctx.playing);
        if (newIndex !== ctx.currentIndex) {
			dispatch({ type:'PHRASE_CHANGE', payload: {phraseIndex: newIndex}});
			// props.onPhraseChange(newIndex);
			setCurrentIndex(newIndex);
        }
    }

    const play = async () => {
        await mediaRef.current.play();
        updateMediaState();
		updateMetadata();
    };

    const pause = () => {
        mediaRef.current.pause();
        if(translatedMediaRef.current) translatedMediaRef.current.pause();
        updateMediaState();
    }

    async function playTranslationIfNeeded(){
		let newIndex = getCurrentIndex();
        //if new phrase and should play translation, then do this before handling anything else
        if (newIndex !== ctx.currentIndex && isplaying() && ctx.playTranslation && getTranslatedMediaUrl() && ctx.lastTranslationIndex !== newIndex) {
			console.log(`playing translation for ${newIndex}`);
            await playTranslation();
			setLastTranslationIndex(newIndex);
        }
	}
	
	async function playTranslationOnStart() {
		let newIndex = getCurrentIndex();
        //if new phrase and should play translation, then do this before handling anything else
		console.log(`newIndex=${newIndex}, ctx.currentIndex ${ctx.currentIndex}, ${mediaRef.current.currentTime}, ${isplaying()}, ${getTranslatedMediaUrl()}, lastTranslationIndex=${ctx.lastTranslationIndex}`);
        if (newIndex === 0 && ctx.currentIndex === 0 && isplaying() && ctx.playTranslation && getTranslatedMediaUrl() && ctx.lastTranslationIndex !== newIndex) {
			console.log(`playing initial translation for ${newIndex}`);
			setLastTranslationIndex(newIndex);
			await playTranslation();
        }
	}

    function playTranslation(){
		return new Promise(async (resolve, reject) => {
			'ended error'.split(' ').forEach(eventName => {
				events.once(translatedMediaRef.current, eventName, async() => {
					await mediaRef.current.play();
					updateMediaState();
					resolve();
				});
			});

			mediaRef.current.pause();
			try {
				translatedMediaRef.current.load();
				await translatedMediaRef.current.play();

			} catch (error) {
				await mediaRef.current.play();
			}

		})
    }

	function getCurrentIndex(time) {
		time = time || (mediaRef.current && mediaRef.current.currentTime) || 0;	//sometimes it throws an NRE
		let entries = props.model.captions.entries;
		time += 0.01; //to compensate for rounding errors
		var prevMarkerTime = 0;
		var index = 0;
		for (var i = 0; i < entries.length; i++) {
			var markerTime = entries[i].time;
			if (time >= markerTime) { //i-th marker is earlier than now
				if (markerTime > prevMarkerTime) { //i-th marker is later than prevtime -- should be considered as the latest before now
					index = i;
					prevMarkerTime = markerTime;
				}
			}
		}
		return index;
	}

	function moveToPrevMarker(shift) {
		shift = (Number.isFinite(shift) && shift) || 1;  //since the arg can be smth else, we provide a default of 1s for this case
		let entries = props.model.captions.entries;
		var currentIndex =
			getCurrentIndex(mediaRef.current.currentTime - shift); //make it bigger so that we can click twice even when it's playing and still get back to the prev marker
		// console.log(mediaRef.current.currentTime, shift, entries[currentIndex].time, currentIndex, getCurrentIndex(mediaRef.current.currentTime));

		dispatch({ type: 'FORCE_INDEX', payload: {forcedIndex: currentIndex} });
	}

	function moveToNextMarker() {
		var currentIndex = getCurrentIndex();
		let entries = props.model.captions.entries;
		if (currentIndex + 1 < entries.length) {
			dispatch({ type: 'FORCE_INDEX', payload: {forcedIndex: currentIndex + 1} });
		}
	}

	function speedUp() {
		var newPlaybackRate = playbackRate + 0.1;
		setPlaybackRate(newPlaybackRate);
		mediaRef.current.playbackRate = newPlaybackRate;
		speedNotify(`${t('play-speed')} ${Math.round(newPlaybackRate*100)}%`);
	}

	function speedDown() {
		var newPlaybackRate = playbackRate - 0.1;
		setPlaybackRate(newPlaybackRate);
		mediaRef.current.playbackRate = newPlaybackRate;
		speedNotify(`${t('play-speed')} ${Math.round(newPlaybackRate*100)}%`);
	}

	function speedNotify(message) {

		Toastify({
			text: message,
			selector: 'controlsWrapper',
			duration: 1000,
			close: false,
			gravity: "bottom", // `top` or `bottom`
			position: "center", // `left`, `center` or `right`
			className: "success speed-info",
			stopOnFocus: true, // Prevents dismissing of toast on hover
			offset: { x: 0, y: 40 },
		  }).showToast();		
	}

	let somethingPlaying = isplaying() || translationisplaying();

    return (
      <div id="controlsWrapper">
        <button className="btn btn-sm player-btn" id="rewinder" onClick={moveToPrevMarker}>
          <i className="icon-fast-backward" style={{fontSize: '28px'}}></i>
        </button>

        <div id="playPauseWrapper">
			
			<button onClick={speedUp} className="speeder btn" title={`${Math.round(playbackRate*100)}%`}>+</button>

			{!somethingPlaying && (
				<button className="btn btn-sm player-btn btnPlayPause " id="player" onClick={play}>
					<i className="icon-play"></i>
				</button>
			)}
	
			{somethingPlaying && (
				<button className="btn btn-sm player-btn btnPlayPause " id="pauser" onClick={pause}>
					<i className="icon-pause"></i>
				</button>
			)}
			
			<button onClick={speedDown} className="speeder speedDown btn" title={`${Math.round(playbackRate*100)}%`}>-</button>
		</div>

        <button className="btn btn-sm player-btn" id="forwarder" onClick={moveToNextMarker}>
          <i className="icon-fast-forward" style={{fontSize: '28px'}}></i>
        </button>

		<audio id="media" preload="auto" loop={true} ref={mediaRef}>
          	<source src={props.model.mediaPath} type="audio/mpeg"/>
        </audio>

        {props.hasTranslatedMedia &&
          (<audio id="translatedMedia" preload="auto" ref={translatedMediaRef}>
            <source src={getTranslatedMediaUrl()} type="audio/mpeg"/>
          </audio>)
        }
      </div>
    )

	function getTranslatedMediaUrl(){
		if (props.model.translationMediaPath) {
			const url = props.model.translationMediaPath.replace("{index}", getCurrentIndex());
			//if the file exists on the server, return the url
			if (props.model.translatedMediaFiles.find(file => url.endsWith(file))) {
				return url;
			} else {
				return null;	//if the file's missing, return null so that we don't play the translation in playTranslationIfNeeded()
			}
			
		}

	}

	function translationisplaying(){
		return translatedMediaRef.current && !translatedMediaRef.current.paused;
	}

	//display title and module in Chrome
	function updateMetadata(){
		let translation = props.model.translation;
		if ("mediaSession" in navigator) {
			navigator.mediaSession.metadata = new window.MediaMetadata({
				title: translation.title,
				artist: "flyent",
				album: translation.moduleName
			});
		}
	}
	
	function handleMediaButtons() {

		const actionHandlers = [
			['play',          	play ],
			['pause',         	pause ],
			['previoustrack',	moveToPrevMarker ],
			['nexttrack',     	moveToNextMarker ],
			['stop',          	pause ],
		];
		  
		for (const [action, handler] of actionHandlers) {
			try {
				navigator.mediaSession.setActionHandler(action, handler);
			} catch (error) {
				console.log(`The media session action "${action}" is not supported yet.`);
			}
		}
	}

	function resetMediaButtons() {

		const actionHandlers = [
			['play',          	play ],
			['pause',         	pause ],
			['previoustrack',	moveToPrevMarker ],
			['nexttrack',     	moveToNextMarker ],
			['stop',          	pause ],
		];

		for (const [action, handler] of actionHandlers) {
			try {
				navigator.mediaSession.setActionHandler(action, null);
			} catch (error) {
				console.log(`The media session action "${action}" is not supported yet.`);
			}
		}
	}

	//use the ctx object to reference state variables inside closures
	function useLocalContext(data) {
		const [ctx] = React.useState({});

		Object.keys(data).forEach(key => {
		  ctx[key] = data[key]
		});

		return ctx;
	}
}

export default PlayerControls;
